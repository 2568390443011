import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { store } from "../store";
import AddItemModal from "../UIComponents/Modals/AddItemModal";

const Footer = () => {
  const [show, setShow] = useState(false);
  const { state } = useContext(store);

  const handleSellItem = () => {
    if (state.user.id) setShow(true);
    else alert("Please login first to sell an item.");
  };

  return (
    <>
      <AddItemModal open={show} setOpen={setShow} />
      <footer
        className="bg-app-primary pt-8 px-4 text-white absolute bottom-0 left-0 w-full right-0 hidden md:block"
        style={{ width: "100%", left: "0", right: "0" }}
      >
        <section className="">
          <div className="max-w-6xl mx-auto mb-2">
            <div className="md:flex justify-between">
              <div className="mb-10">
                <h6 className="text-uppercase fw-bold mb-3">Sell</h6>
                <p>
                  <a href="#!" onClick={handleSellItem} className="text-reset">
                    <u> Sell an Item</u>
                  </a>
                </p>
              </div>

              <div className="mr-auto md:mr-0 ml-auto md:mb-0 mb-6 text-center md:text-right mt-2">
                <a href="#!" className="btn btn-outline-light">
                  Get the app
                </a>
                <div className="mt-4" style={{ fontSize: "20px" }}>
                  <a
                    href="https://www.facebook.com/groups/TheASUList"
                    className="mr-4 text-reset"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <a href="#!" className="mr-4 text-reset">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a
                    href="https://www.instagram.com/thelistasu"
                    className="mr-4 text-reset"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  {/* <a href="#!" className="text-reset">
                    <i className="fab fa-linkedin"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="max-w-6xl mx-auto flex justify-center md:justify-between pb-5 pt-4 border-t border-gray-200 border-opacity-60">
          <div className="mr-4">
            <span>2023 ASU List Inc.</span>
          </div>

          <div className="flex items-center text-sm sm:text-base">
            <Link to="/terms">
              <u>Terms of Service</u>
            </Link>
            <span className="mx-1">/</span>
            <Link to="/privacy">
              <u>Privacy Policy</u>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
