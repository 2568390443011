import React, { useState } from "react";
import Footer from "../../components/Footer";

const Contact = () => {
  const [question, setQuestion] = useState(
    "I have questions about shipping on ASU LIST"
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      `form Data: \n question: ${question} \n name: ${name} \n email: ${email} \n transactionId: ${transactionId} \n description: ${description}`
    );
  };

  return (
    <>
      <div className="pt-6 md:pt-12 pb-24 md:pb-72 max-w-lg mx-auto">
        <h1 className="text-gray-700 text-3xl font-bold mb-6">
          Shipping on ASU LIST
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <select
              autoComplete="off"
              className="focus:outline-none py-2 px-2 mb-3 block w-full border rounded-md font-light text-sm text-gray-700"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            >
              <option
                value="I have questions about shipping on ASU LIST"
                selected
              >
                I have questions about shipping on ASU LIST
              </option>

              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
              <option value={"I have questions about shipping on ASU LIST"}>
                I have questions about shipping on ASU LIST
              </option>
            </select>
          </div>
          <div>
            <h3 className="text-lg text-gray-700 font-bold mb-4">
              Fill out the information below
            </h3>
            <div>
              <label
                htmlFor="name"
                className="mb-1 inline-block text-sm font-medium text-gray-600"
              >
                Name (required)
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                id="name"
                type="text"
                className="focus:outline-none py-2 px-2 mb-3 block w-full border rounded-md font-light text-sm text-gray-700"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="mb-1 inline-block text-sm font-medium text-gray-600"
              >
                Email (required)
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                id="email"
                type="text"
                className="focus:outline-none py-2 px-2 mb-3 block w-full border rounded-md font-light text-sm text-gray-700"
              />
            </div>
            <div>
              <label
                htmlFor="transaction-id"
                className="mb-1 inline-block text-sm font-medium text-gray-600"
              >
                Transaction ID / Order ID
              </label>
              <input
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                id="transaction-id"
                type="text"
                className="focus:outline-none py-2 px-2 mb-3 block w-full border rounded-md font-light text-sm text-gray-700"
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="mb-1 inline-block text-sm font-medium text-gray-600"
              >
                Description (required)
              </label>
              <textarea
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                className="focus:outline-none py-2 px-2 mb-3 block w-full border rounded-md font-light text-sm text-gray-700"
                rows={4}
              />
            </div>
          </div>
          <button className="w-full block disabled:opacity-75 px-4 py-2 rounded-full bg-app-primary text-white font-medium hover:bg-app-primary-dark transition duration-200">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
