import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import { store } from "../../store";
import api from "../../utils/api";

import "./profile.css";

export default function Profile() {
  const { register, handleSubmit, watch, errors } = useForm();
  const { state, dispatch } = useContext(store);
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // handle form submission
  const onSubmit = (data) => {
    if (data.password !== data.password_confirmation) {
      alert("Confirm password mismatched!");
      return;
    }

    saveUser(data);
  };

  const saveUser = (formData) => {
    setDisabled(true);
    setLoading(true);

    api.get(`${process.env.REACT_APP_API}/sanctum/csrf-cookie`).then(() => {
      api
        .post(`${process.env.REACT_APP_API}/profile`, formData)
        .then((response) => {
          if (response.data.user) {
            localStorage.setItem("asulist_user", true);

            dispatch({
              type: "LOGGEDIN",
              payload: response.data.user,
            });

            // history.replace("/");
            setDisabled(false);
            setLoading(false);
            window.alert("Profile updated.");
          }
        })
        .catch((errors) => {
          // if (errors.response.data.errors) {
          //   if (errors.response.data.errors.email)
          //     // notify(`Error! ${errors.response.data.errors.email[0]}`, "error");
          //   if (errors.response.data.errors.company)
          //     notify(
          //       `Error! ${errors.response.data.errors.company[0]}`,
          //       "error"
          //     );
          // }

          setDisabled(false);
          setLoading(false);
        });
    });
  };

  // const navDropdownTitle = <i className="fa fa-ellipsis-v"></i>;

  const [profileImg, setProfileImg] = useState(state.user?.image);

  const fileRef = useRef();
  const selectPhoto = () => fileRef?.current?.click?.();

  const [imgLoading, setImgLoading] = useState(false);

  const handlePhotoUpdate = (e) => {
    e.preventDefault();

    if (!e?.target?.files?.[0]) {
      return;
    }
    setImgLoading(true);

    let fd = new FormData();
    fd.append("image", e?.target?.files?.[0]);

    api
      .post("user/avatar", fd)
      .then((response) => {
        if (response?.data?.data) {
          toast.success(response?.data?.message);
          setProfileImg(response.data.data?.["image"]);

          dispatch({
            type: "LOGGEDIN",
            payload: { ...state.user, image: response.data.data?.["image"] },
          });
        }

        setImgLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        }
        setImgLoading(false);
      });
  };

  return (
    <>
      <div className="User-Profile Profile pb-20 md:pb-60">
        {/* <Nav
          className="position-absolute desktop-hidden block md:hidden"
          style={{ right: "0" }}
        >
          <NavDropdown title={navDropdownTitle} id="profile-dropdown">
            <NavDropdown.Item>Line 1</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>Line 2</NavDropdown.Item>
          </NavDropdown>
        </Nav> */}

        {state?.user?.id && (
          <div className="text-center mt-12 pb-3 mb-5 max-w-md mx-auto border-b">
            <div className="cursor-pointer relative rounded-full w-28 sm:w-32 md:w-36 h-28 sm:h-32 md:h-36 mx-auto">
              <img
                src={
                  state?.user?.image
                    ? `${process.env.REACT_APP_IMAGE_PATH}/${profileImg}`
                    : "/empty-profile.png"
                }
                alt={state?.user?.name}
                className="rounded-full object-cover object-center inline-block w-28 sm:w-32 md:w-36 h-28 sm:h-32 md:h-36 border"
              />

              <input
                type="file"
                ref={fileRef}
                onChange={handlePhotoUpdate}
                hidden
                accept="image/*"
                className="hidden"
              />
              <button
                type="button"
                disabled={imgLoading}
                onClick={selectPhoto}
                className="absolute group inset-0 text-white text-[40px] w-full rounded-full z-10 bg-black bg-opacity-0 hover:bg-opacity-50 transition flex justify-center items-center text-center"
              >
                <div className="opacity-0 group-hover:opacity-100 transition duration-300 transform translate-y-1 group-hover:translate-y-0">
                  <i className="far fa-camera"></i>
                </div>
                <div className="absolute md:top-2 top-0 md:right-2 right-0 z-10 flex items-center justify-center bg-gray-50 text-gray-700 w-8 h-8 rounded-full border shadow text-base">
                  {imgLoading ? (
                    <span className="transform scale-[.6] inline-block">
                      <Loader />
                    </span>
                  ) : (
                    <i className="fas fa-pencil"></i>
                  )}
                </div>
              </button>
            </div>

            <h4 className="pt-2 text-2xl text-gray-700 m-0">
              <Link
                className="hover:text-gray-800 transition"
                to={`/profile/${state?.user?.id}`}
              >
                <span className="font-bold">{state.user.name}</span>
              </Link>
              {/* <i className="far fa-check-circle ml-3 text-gray-500"></i> */}
            </h4>
          </div>
        )}

        <div className="max-w-6xl mx-auto mb-10">
          <Link
            to="/profile-info"
            className="flex items-center justify-between border-b py-2 sm:py-3 hover:bg-gray-100 px-3 rounded-t sm:rounded-t-md transition"
          >
            <div className="flex">
              <div className="text-xl text-gray-500 mr-3.5">
                <i className="far fa-user"></i>
              </div>
              <div className="">
                <h3 className="text-gray-800 text-base md:text-lg font-light">
                  Basic Info
                </h3>

                <p className="font-light text-gray-500 text-sm">
                  View / Edit your profile info
                </p>
              </div>
            </div>
            <div className="icon text-lg text-gray-500">
              <i className="fa fa-chevron-right"></i>
            </div>
          </Link>
          <Link
            to="/wishlist"
            className="flex items-center justify-between border-b py-2 sm:py-3 hover:bg-gray-100 px-3 transition"
          >
            <div className="flex">
              <div className="text-xl text-gray-500 mr-3.5">
                <i className="far fa-heart"></i>
              </div>
              <div className="">
                <h3 className="text-gray-800 text-base md:text-lg font-light">
                  Favorites
                </h3>

                <p className="font-light text-gray-500 text-sm">
                  Your collection
                </p>
              </div>
            </div>
            <div className="icon text-lg text-gray-500">
              <i className="fa fa-chevron-right"></i>
            </div>
          </Link>
          <Link
            to="/selling"
            className="flex items-center justify-between py-2 sm:py-3 hover:bg-gray-100 px-3 rounded-b sm:rounded-b-md transition"
          >
            <div className="flex">
              <div className="text-xl text-gray-500 mr-3.5">
                <i className="far fa-tags"></i>
              </div>
              <div className="">
                <h3 className="text-gray-800 text-base md:text-lg font-light">
                  Items
                </h3>

                <p className="font-light text-gray-500 text-sm">
                  List of Items created by you
                </p>
              </div>
            </div>
            <div className="icon text-lg text-gray-500">
              <i className="fa fa-chevron-right"></i>
            </div>
          </Link>
          {/* 
          <hr />

          <div className="Profile-Menu-Item">
            <div className="icon-left">
              <i className="far fa-user"></i>
            </div>
            <div className="flex-fill">
              <div>My Profile</div>

              <small>Change your profile details</small>
            </div>
            <div className="icon">
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>

          <hr />

          <div className="Profile-Menu-Item">
            <div className="icon-left">
              <i className="far fa-cog"></i>
            </div>
            <div className="flex-fill">
              <div>Settings</div>

              <small>Password and Security</small>
            </div>
            <div className="icon">
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>

          <hr />

          <div className="Profile-Menu-Item">
            <div className="icon-left">
              <i className="far fa-tags"></i>
            </div>

            <div className="flex-fill">
              <div>Posts</div>

              <small>Sold & Selling</small>
            </div>
            <div className="icon">
              <i className="fa fa-chevron-right"></i>
            </div>
          </div> */}
        </div>
      </div>

      <Footer />
    </>

    // <Container className="Login">
    //   <Row>
    //     <Col lg={6} className="mx-auto my-5 px-0">
    //       <Form
    //         className="pb-5 px-4 rounded shadow"
    //         onSubmit={handleSubmit(onSubmit)}
    //       >
    //         <h3 className="text-center pt-4 pb-3">Profile</h3>

    //         <Form.Group controlId="formBasicName">
    //           <Form.Label>Name</Form.Label>
    //           <Form.Control autoComplete="off"
    //             type="text"
    //             name="name"
    //             defaultValue={state.user.name}
    //             ref={register()}
    //             required
    //           />
    //         </Form.Group>

    //         <Form.Group controlId="formBasicUsername">
    //           <Form.Label>Username</Form.Label>
    //           <Form.Control autoComplete="off"
    //             type="text"
    //             name="username"
    //             defaultValue={state.user.username}
    //             ref={register()}
    //             required
    //           />
    //         </Form.Group>

    //         <Form.Group controlId="formBasicEmail">
    //           <Form.Label>Email address</Form.Label>
    //           <Form.Control autoComplete="off"
    //             type="email"
    //             name="email"
    //             defaultValue={state.user.email}
    //             // ref={register()}
    //             disabled
    //           />
    //         </Form.Group>

    //         <Form.Text className="text-muted">
    //           Leave password fields black if you don't want to change password.
    //         </Form.Text>

    //         <Form.Group controlId="formBasicPassword">
    //           <Form.Label>Password</Form.Label>
    //           <Form.Control autoComplete="off" type="password" name="password" ref={register()} />
    //         </Form.Group>

    //         <Form.Group controlId="formBasicPasswordConfirm">
    //           <Form.Label>Confirm Password</Form.Label>
    //           <Form.Control autoComplete="off"
    //             type="password"
    //             name="password_confirmation"
    //             ref={register()}
    //           />
    //         </Form.Group>

    //         <Button
    //           variant="dark"
    //           type="submit"
    //           className="position-relative"
    //           disabled={disabled}
    //           block
    //         >
    //           <span>Update</span>

    //           {loading && <Loader />}
    //         </Button>
    //       </Form>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
