import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { store } from "../store";
import api from "../utils/api";
import NavPagesDropdown from "../UIComponents/Dropdowns/NavPagesDropdown";
import NavProfileDropdown from "../UIComponents/Dropdowns/NavProfileDropdown";
import AddItemModal from "../UIComponents/Modals/AddItemModal";
import NavMoreDropdown from "../UIComponents/Dropdowns/NavMoreDropdown";
import HeaderSearch from "../UIComponents/Search/HeaderSearch";
import queryString from "query-string";
import LocationFilter from "../UIComponents/Filters/LocationFilter";
import NavInboxDropdown from "../UIComponents/Dropdowns/NavInboxDropdown";

export default function Navigation() {
  const history = useHistory();
  const { state, dispatch } = useContext(store);
  const [show, setShow] = useState(false);

  const onLogout = async () => {
    await api.post(`${process.env.REACT_APP_API}/api/logout`);
    localStorage.removeItem("asulist_user");
    dispatch({ type: "LOGGEDOUT" });
    history.push("/");
  };

  const onSearch = (value) => {
    // setQuery(value);

    // setTimeout(() => {
    //   if (history.location.pathname !== "/") history.push("/");
    // }, 100);

    const parsedQuery = queryString.parse(history?.location?.search);

    const queryArray = [];

    if (value) {
      queryArray.push(`s=${value}`);
    }
    if (parsedQuery?.c) {
      queryArray.push(`c=${parsedQuery?.c}`);
    }
    if (parsedQuery?.zip) {
      queryArray.push(`zip=${parsedQuery?.zip}`);
    }

    history.push(`/?${queryArray.join("&")}`);

    // if (value) {
    //   history.push(
    //     `/?s=${value}${parsedQuery?.c ? `&c=${parsedQuery?.c}` : ""}`
    //   );
    // } else {
    //   history.push(`${parsedQuery?.c ? `?c=${parsedQuery?.c}` : "/"}`);
    // }
  };

  const handleCategoryClick = (category) => {
    const parsedQuery = queryString.parse(history.location?.search);

    const queryArray = [];

    if (parsedQuery?.s) {
      queryArray.push(`s=${parsedQuery?.s}`);
    }
    if (
      String(category?.id) !== queryString.parse(history.location?.search)?.c
    ) {
      queryArray.push(`c=${category?.id}`);
    }
    if (parsedQuery?.zip) {
      queryArray.push(`zip=${parsedQuery?.zip}`);
    }

    history.push(`/?${queryArray.join("&")}`);

    // if (
    //   String(category.id) === queryString.parse(history.location?.search)?.c
    // ) {
    //   history.push(`/${parsedQuery?.s ? `?s=${parsedQuery?.s}` : ``}`);
    //   return;
    // }

    // history.push(
    //   `/${
    //     parsedQuery?.s
    //       ? `?s=${parsedQuery?.s}&c=${category?.id}`
    //       : `?c=${category?.id}`
    //   }`
    // );
  };

  const [categoryList, setCategoryList] = useState([]);
  const [moreList, setMoreList] = useState([]);

  const itemsRef = React.useRef([]);

  if (itemsRef.current.length !== categoryList.length) {
    // add or remove refs
    itemsRef.current = Array(categoryList.length)
      .fill()
      .map((_, i) => itemsRef.current[i] || createRef());
  }

  useEffect(() => {
    setCategoryList(state.categories);
  }, [state.categories]);

  const headerRef = useRef();
  const listRef = useRef();
  const moreListRef = useRef();

  useEffect(() => {
    const calcWidth = () => {
      if (!isAllowed()) return;

      let navWidth = 0;
      let morewidth = moreListRef?.current?.getBoundingClientRect?.()?.width;

      itemsRef.current.forEach((item) => {
        navWidth += item.current.getBoundingClientRect().width + 4;
      });

      const header = headerRef.current;

      const availablespace =
        header?.getBoundingClientRect?.()?.width - morewidth;

      if (navWidth > availablespace) {
        let lastItem = null;

        setCategoryList((prevItems) => {
          lastItem = prevItems[prevItems?.length - 1];

          return prevItems.filter((item) => item.id !== lastItem?.id);
        });
        setMoreList((prevItems) => {
          let alreadyExist = false;
          prevItems.forEach((item) => {
            if (item.id === lastItem.id) {
              alreadyExist = true;
            }
          });

          if (alreadyExist) {
            return prevItems;
          }
          return [
            {
              dataWidth:
                itemsRef?.current?.[0]?.current?.getBoundingClientRect?.()
                  ?.width,
              ...lastItem,
            },
            ...prevItems,
          ];
        });
      } else {
        let firstMoreItem = null;

        setMoreList((prevMoreList) => {
          firstMoreItem = prevMoreList[0];
          return prevMoreList;
        });

        if (navWidth + firstMoreItem?.dataWidth < availablespace) {
          setMoreList((prevMoreList) => {
            firstMoreItem = prevMoreList[0];
            return prevMoreList.filter(
              (category) => category?.id !== prevMoreList?.[0]?.id
            );
          });
          setCategoryList((prevCategoryList) => [
            ...prevCategoryList,
            firstMoreItem,
          ]);
        }
      }
    };

    if (state.categories.length > 0) {
      window.addEventListener("resize", calcWidth);
      calcWidth();
    }

    return () => {
      window.removeEventListener("resize", calcWidth);
    };
  }, [categoryList, moreList]);

  const location = useLocation();

  const [currCategoryId, setCurrCategoryId] = useState(null);

  useEffect(() => {
    const parsedQuery = queryString.parse(location?.search);
    setCurrCategoryId(parsedQuery?.c);
  }, [location?.search]);

  const isAllowed = () => {
    if (
      history.location.pathname !== "/login" &&
      history.location.pathname !== "/register" &&
      history.location.pathname !== "/forgot-password" &&
      !history.location.pathname.includes("/reset-password") &&
      history.location.pathname !== "/about" &&
      history.location.pathname !== "/terms" &&
      history.location.pathname !== "/profile-info" &&
      history.location.pathname !== "/profile" &&
      !history.location.pathname.includes("/profile") &&
      history.location.pathname !== "/privacy" &&
      history.location.pathname !== "/contact" &&
      !history.location.pathname.includes("/inbox")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <header
      className={`${
        isAllowed() ? "py-2 flex" : "py-3.5 hidden md:flex"
      } items-center border-b container-fluid max-w-full`}
    >
      <div className="w-full" ref={headerRef}>
        <div
          className="flex flex-nowrap justify-start items-center px-0"
          id="header"
        >
          <Link to="/" className="sm:block hidden mr-3 whitespace-nowrap">
            {/* <div className="text-lg lg:text-xl text-gray-600">
              <span style={{ fontWeight: 700 }}>
                {process.env.REACT_APP_NAME}
              </span>
            </div> */}
            <img src="/logo.png" alt="ASU LIST" className="w-12 md:w-[52px]" />
          </Link>

          {/* <form
            className='w-full md:w-1/3 lg:w-2/5 mr-auto relative'
            onSubmit={onSearch}
          >
            <div className=''>
              <i
                className='fas fa-search absolute text-lg text-gray-600 cursor-pointer hover:text-gray-700 top-1/2 transform -translate-y-1/2 right-4 search-icon'
                onClick={onSearch}
              ></i>

              <input
                type='text'
                placeholder='What are you looking for?'
                className='w-full text-sm lg:text-base bg-gray-50 border focus:outline-none font-light rounded-full pl-4 pr-10 py-1.5'
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </form> */}
          <div className="w-full md:w-[30%] lg:w-[32%] xl:w-[35%] mr-auto relative flex items-center">
            {isAllowed() ? (
              <>
                <HeaderSearch onSearch={onSearch} />
                <div className="ml-2 md:ml-2 mr-0 md:mr-2">
                  <LocationFilter />
                </div>
              </>
            ) : null}
          </div>

          <a
            href="https://www.facebook.com/groups/TheASUList"
            className="text-sm mr-3 text-app-primary hover:text-app-primary-dark transitionl hover:underline hidden md:block"
          >
            <span className="hidden lg:inline"> Check out our</span> Facebook
            Group
          </a>

          {state?.user?.id ? (
            <button
              className="btn btn-primary mr-2 hidden md:block text-sm lg:text-base"
              variant="default"
              onClick={() => {
                if (state.user.id) setShow(true);
                else alert("Please login first to sell an item.");
              }}
            >
              Sell <span className="hidden xl:inline-block"> something</span>
              <span className="xl:hidden"> item</span>
            </button>
          ) : null}

          {!state?.user?.id ? (
            <div className="mx-4 hidden md:block">
              <NavPagesDropdown />
            </div>
          ) : null}

          <nav className="hidden md:flex items-center">
            {state.user.id ? (
              <>
                <ul className="mr-2 flex items-center divide-x border-r border-l">
                  <li>
                    <Link
                      to="/wishlist"
                      className="flex flex-col text-xs items-center text-gray-700 py-1 w-[60px] lg:w-[70px] hover:bg-gray-100 transition"
                    >
                      <span className="inline-block -mb-0.5 text-base lg:text-lg">
                        <i className="far fa-heart"></i>
                      </span>
                      <span>Saved</span>
                    </Link>
                  </li>
                  <li>
                    {/* <NavInboxDropdown /> */}

                    <Link
                      to="/inbox"
                      className="flex flex-col text-xs items-center text-gray-700 py-1 w-[60px] lg:w-[70px] hover:bg-gray-100 transition"
                    >
                      <span className="inline-block -mb-0.5 text-base lg:text-lg">
                        <i className="far fa-comment-alt"></i>
                      </span>
                      <span>Inbox</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/selling"
                      className="hidden lg:flex flex-col text-xs items-center text-gray-700 py-1 w-[60px] lg:w-[70px] hover:bg-gray-100 transition"
                    >
                      <span className="inline-block -mb-0.5 text-base lg:text-lg">
                        <i className="far fa-dollar-sign"></i>
                      </span>
                      <span>Selling</span>
                    </Link>
                  </li>
                </ul>
                <NavProfileDropdown onLogout={onLogout} />
              </>
            ) : (
              <div className="space-x-5 text-gray-700">
                <Link
                  to="/login"
                  className="font-medium text-[15px] hover:underline"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="font-medium text-[15px] hover:underline"
                >
                  Register
                </Link>
              </div>
            )}
          </nav>

          {/* <ItemModel show={show} setShow={setShow} /> */}
          <AddItemModal open={show} setOpen={setShow} />
        </div>
        {isAllowed() ? (
          <div className="flex items-center">
            <ul
              ref={listRef}
              className={`flex items-center w-full flex-nowrap sm:-ml-3  mt-3 space-x-1 justify-between pb-1 text-sm sm:text-[15px] text-gray-700 ${
                moreList?.length === 0 ? "overflow-hidden" : ""
              }`}
            >
              {categoryList.map((category, i) => (
                <li
                  ref={itemsRef.current[i]}
                  className={`category-item ${
                    currCategoryId === String(category?.id)
                      ? "text-gray-800"
                      : "text-gray-500"
                  } whitespace-nowrap px-2 sm:px-3 py-1 rounded hover:text-gray-800 inline-block cursor-pointer
                `}
                  key={category?.id}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </li>
              ))}
              <li className="" ref={moreListRef}>
                {moreList.length > 0 ? (
                  <NavMoreDropdown
                    list={moreList}
                    currCategoryId={currCategoryId}
                    handleCategoryClick={handleCategoryClick}
                  ></NavMoreDropdown>
                ) : null}
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </header>
  );
}
