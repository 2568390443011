import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { store } from "../../store";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavProfileDropdown({ onLogout }) {
  const { state } = useContext(store);
  return (
    <Menu as="div" className="relative inline-block text-left shrink-0">
      <div>
        <Menu.Button className="relative inline-flex w-full justify-center rounded-md py-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none">
          <img
            alt={state?.user?.name}
            src={
              state?.user?.image
                ? `${process.env.REACT_APP_IMAGE_PATH}/${state.user.image}`
                : "/empty-profile.png"
            }
            className="border rounded-full object-cover object-center w-10 h-10"
          />

          {/* <ChevronDownIcon
            className="ml-0.5 mt-auto h-5 w-5 "
            aria-hidden="true"
          /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "px-4 py-3 text-sm flex items-center"
                  )}
                >
                  <img
                    alt={state?.user?.name}
                    src={
                      state?.user?.image
                        ? `${process.env.REACT_APP_IMAGE_PATH}/${state.user.image}`
                        : "/empty-profile.png"
                    }
                    className="border rounded-full w-14 h-14 object-cover object-center mr-3"
                  />
                  <div>
                    <p className="truncate text-base font-medium text-gray-900">
                      {state?.user?.name}
                    </p>
                    <p className="truncate text-sm text-gray-700">
                      View Profile
                    </p>
                  </div>
                </Link>
              )}
            </Menu.Item>
          </div>

          {/* <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Support
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  License
                </a>
              )}
            </Menu.Item>
          </div> */}
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onLogout}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block w-full px-4 py-2 text-left text-sm"
                  )}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
