import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
// import api from "../../utils/api";

const messages = [
  "Hi, is this still available?",
  "Hi, i'd like to buy this",
  "Hi, can you meet today",
];

export default function ChatModal({ open, setOpen, item }) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!open) {
      setMessage("");
    }
  }, [open]);

  const sendMessage = async (msgText) => {
    try {
      await api.post(
        `${process.env.REACT_APP_API}/api/items/${item.id}/messages`,
        {
          text: msgText,
          receiver_id: item.user_id,
        }
      );
      toast.success("Your message has been sent.");
      setOpen(false);
    } catch (err) {
      console.log("Send Message Error: ", err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(message);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-8 text-left shadow-xl transition-all sm:my-8 w-full  max-w-md sm:p-6">
                <div className="flex items-center justify-between mb-3">
                  <button className="text-sm text-gray-700 invisible opacity-0 pointer-events-none">
                    Cancel
                  </button>
                  <h3 className="text-center font-bold text-gray-700 text-xl">
                    Send a Message
                  </h3>
                  <button
                    className="text-sm text-gray-700"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div className="mt-5 sm:mt-6">
                  <p className="text-sm font-medium">
                    Click a message to send or write your own:
                  </p>
                  <div className="text-sm flex flex-col items-start space-y-3 mt-4">
                    {messages.map((msg) => (
                      <button
                        onClick={() => sendMessage(msg)}
                        type="button"
                        className="inline-block py-1.5 px-3 rounded-full border border-app-primary text-app-primary bg-app-primary bg-opacity-0 hover:bg-opacity-5 transition"
                      >
                        {msg}
                      </button>
                    ))}
                  </div>
                  <form className="mt-3" onSubmit={handleSubmit}>
                    <label
                      htmlFor="message"
                      className="block text-xs font-bold mb-0.5 text-gray-700"
                    >
                      New message
                    </label>
                    <div className="mt-1 flex space-x-2">
                      <input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        id="message"
                        name="message"
                        type="text"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-2.5 py-1.5 placeholder-gray-400 shadow-sm focus:outline-none text-sm"
                      />
                      <button
                        type="submit"
                        className="text-white bg-app-primary  rounded-md px-2.5"
                      >
                        <PaperAirplaneIcon className="w-5"></PaperAirplaneIcon>
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
