import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import "./App.css";

import { store } from "./store";
import api from "./utils/api";

import GuardedRoute from "./guards/GuardedRoute";
import UnguardedRoute from "./guards/UnguardedRoute";

// Components
import Navigation from "./components/Navigation";
import Menu from "./components/Menu";

// Pages
import UserProfile from "./pages/user-profile";
import Register from "./pages/register";
import Profile from "./pages/profile";
import Login from "./pages/login";
import Item from "./pages/item";
// import Rent from "./pages/rent";
import Home from "./pages/home";
import AddItem from "./pages/add-item";
import EditItem from "./pages/edit-item";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Forgot from "./pages/forgot/Forgot";
import Reset from "./pages/reset/Reset";
import About from "./pages/about/about";
import Wishlist from "./pages/wishlist/wishlist";
import Terms from "./pages/terms/terms";
import Privacy from "./pages/privacy/privacy";
import ProfileInfo from "./pages/profile/ProfileInfo";
import Items from "./pages/profile/Items";
import Contact from "./pages/contact";
import Inbox from "./pages/inbox/Inbox";
import Message from "./pages/inbox/Message";

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import EnterNameModal from "./UIComponents/Modals/EnterNameModal";
import queryString from "query-string";

Pusher.logToConsole = process.env.REACT_APP_PUSHER_LOG_TO_CONSOLE;
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        api
          .post("/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(null, response.data);
          })
          .catch((error) => {
            callback(error);
          });
      },
    };
  },
});

export default function App() {
  const { state, dispatch } = useContext(store);
  const [loading, setLoading] = useState(true);
  // const [query, setQuery] = useState("");

  const fetchUser = async () => {
    if (localStorage.getItem("asulist_user")) {
      await api
        .get("user")
        .then((response) => {
          // console.log("response: ",response);
          if (response.data) {
            dispatch({
              type: "LOGGEDIN",
              payload: response.data.data,
            });

            if (response?.data?.data?.apple_id && !response?.data?.data?.name) {
              dispatch({ type: "SET_IS_NAME_MODAL_OPEN", payload: true });
            }
          }
        })
        .catch((errors) => {
          console.log(errors.response);
          localStorage.removeItem("asulist_user");
        });
    }

    setLoading(false);
  };

  const fetchCategories = async () => {
    api
      .get("categories")
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "SET_CATEGORIES",
            payload: response.data.data,
          });
        }
      })
      .catch((errors) => {
        console.log(errors.response);
      });
  };

  useEffect(() => {
    fetchUser();
    fetchCategories();
  }, []);

  const mountRef = useRef();

  useEffect(() => {
    if (mountRef.current !== "mounted") {
      const parsedQuery = queryString.parse(window?.location?.search);
      console.log("typeof verified: ", typeof parsedQuery?.verified);
      if (parsedQuery?.verified === "1") {
        toast.success("Your email has been verified");
      }
      mountRef.current = "mounted";
    }
  }, []);

  return (
    <div className="relative min-h-screen">
      <Router>
        <Navigation />

        {!loading && (
          <div className="container-fluid">
            <Switch>
              <GuardedRoute
                path="/item/:slug/edit"
                component={EditItem}
                auth={state.user.id}
              />

              <GuardedRoute
                path="/add-item"
                component={AddItem}
                auth={state.user.id}
              />
              <GuardedRoute
                path="/wishlist"
                component={() => <Wishlist />}
                auth={state.user.id}
              />
              <GuardedRoute
                path="/inbox"
                exact
                component={() => <Inbox />}
                auth={state.user.id}
              />
              <GuardedRoute
                path="/inbox/message"
                component={() => <Message />}
                auth={state.user.id}
              />

              {/* <GuardedRoute
              path="/profile/:id"
              component={UserProfile}
              auth={state.user.id}
            /> */}

              <GuardedRoute
                exact
                path="/profile"
                component={Profile}
                auth={state.user.id}
              />
              <Route path="/profile/:id">
                <UserProfile />
              </Route>
              <GuardedRoute
                path="/profile-info"
                component={ProfileInfo}
                auth={state.user.id}
              />
              <GuardedRoute
                path="/selling"
                component={Items}
                auth={state.user.id}
              />

              <UnguardedRoute
                path="/register"
                component={Register}
                auth={state.user.id}
              />

              <UnguardedRoute
                path="/login"
                component={Login}
                auth={state.user.id}
              />
              <UnguardedRoute
                path="/forgot-password"
                component={Forgot}
                auth={state.user.id}
              />
              <UnguardedRoute
                path="/reset-password/:token"
                component={Reset}
                auth={state.user.id}
              />

              <Route path="/item/:slug">
                <Item />
              </Route>

              {/* <Route path="/rent">
              <Rent query={query} />
            </Route> */}

              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </div>
        )}
        <Menu />

        <ToastContainer />

        <EnterNameModal />

        {/* <Button
        className="btn-add-item shadow"
        variant="default"
        onClick={() => setShow(true)}
      >
        <i className="fas fa-camera"></i>
      </Button>

      <ItemModel show={show} setShow={setShow} /> */}
      </Router>
    </div>
  );
}
