import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { store } from "../../store";

export default function NavMoreDropdown({
  list,
  handleCategoryClick,
  currCategoryId,
}) {
  const { state } = useContext(store);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`whitespace-nowrap px-3 py-1 rounded text-gray-500 hover:text-gray-800 inline-block cursor-pointer`}
          // onClick={() => handleCategoryClick(category)}
        >
          More
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ul className="p-1 space-y-1  max-h-[60vh] overflow-y-auto ">
            {list.map((category, i) => (
              <li
                className={`categorymore-item w-full ${
                  currCategoryId === String(category?.id)
                    ? "text-gray-800"
                    : "text-gray-500"
                } whitespace-nowrap px-3 py-1 rounded hover:text-gray-800 inline-block cursor-pointer
                `}
                key={category?.id}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
