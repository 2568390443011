import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

// Components
import MyMasonry from "../../components/MyMasonry";

// Helpers
import api from "../../utils/api";

import { store } from "../../store";

import "./user-profile.css";
import { formatDate } from "../../utils/helpers";
import Footer from "../../components/Footer";

export default function UserProfile() {
  const history = useHistory();
  const { state } = useContext(store);
  const { id } = useParams();

  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);

  const fetchUser = () => {
    api
      .get(`users/${id}`)
      .then((response) => {
        if (response?.data?.data) {
          setUser(response.data.data);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const fetchItems = () => {
    api
      .get(`items?limit=12&user=${id}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          setItems(response.data.data);
        }
      })
      .catch((errors) => console.log(errors));
  };

  useEffect(() => {
    fetchUser();
    fetchItems();
  }, [id]);

  return (
    <div className="pt-6 pb-20 md:pb-60">
      {user.id && (
        <div className="flex border-b pb-6">
          <img
            src={
              user.image
                ? `${process.env.REACT_APP_IMAGE_PATH}/${user.image}`
                : "/empty-profile.png"
            }
            alt={user?.name}
            className="w-32 h-32 mr-4 block flex-shrink-0 rounded-full object-center object-cover border"
          />
          <div className="py-3">
            <h4 className="text-2xl font-medium mb-0.5 text-gray-600">
              {user.name}
            </h4>
            <p className="text-sm text-gray-500 font-light">
              Member since {formatDate(user?.created_at, "MMMM YYYY")}
            </p>
            <div className="text-lg mt-2 text-blue-500 space-x-3">
              <button>
                <i className="far fa-share"></i>
              </button>
              <button>
                <i className="far fa-flag"></i>
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="pt-4 pb-10">
        <h3 className="mb-4 text-xl font-bold text-gray-600">
          Items from this seller
        </h3>

        <div>
          <MyMasonry items={items} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
