import { useContext, useState } from "react";
import AppleLogin from "react-apple-login";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { store } from "../store";
import api from "../utils/api";

import PageLoader from "./PageLoader";

const RegisterWithApple = () => {
  const history = useHistory();
  const { dispatch } = useContext(store);

  const [loading, setLoading] = useState(false);

  const handleCallback = (data) => {
    if (!data.code) return;

    setLoading(true);

    api.get(`${process.env.REACT_APP_API}/sanctum/csrf-cookie`).then(() => {
      api
        .get(
          `${process.env.REACT_APP_API_URI}/login/apple/callback?code=${data.code}`
        )
        .then((response) => {
          if (response?.data?.message) {
            toast.success(response.data.message);
          }

          localStorage.setItem("asulist_user", true);

          dispatch({
            type: "LOGGEDIN",
            payload: response?.data?.data?.user,
          });
          if (
            response?.data?.data?.user?.apple_id &&
            !response?.data?.data?.user?.name
          ) {
            dispatch({ type: "SET_IS_NAME_MODAL_OPEN", payload: true });
          }

          history.replace("/");
        })
        .catch((errors) => {
          if (errors?.response?.data?.message) {
            toast.error(errors?.response?.data?.message);
          }
          setLoading(false);
        });
    });
  };

  return (
    <>
      <PageLoader loading={loading} />

      <AppleLogin
        clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
        redirectURI={`${window.location.origin}/login/apple/callback`}
        responseType={"code"}
        responseMode={"query"}
        callback={handleCallback}
        render={(renderProp) => (
          <div>
            <button
              onClick={renderProp.onClick}
              className="inline-flex w-full items-center justify-center rounded-md bg-black bg-opacity-90 py-2.5 px-4 text-sm text-white  shadow-sm hover:bg-opacity-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                className="mr-2 fill-white"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 640 640"
              >
                <path d="M494.782 340.02c-.803-81.025 66.084-119.907 69.072-121.832-37.595-54.993-96.167-62.552-117.037-63.402-49.843-5.032-97.242 29.362-122.565 29.362-25.253 0-64.277-28.607-105.604-27.85-54.32.803-104.4 31.594-132.403 80.245C29.81 334.457 71.81 479.58 126.816 558.976c26.87 38.882 58.914 82.56 100.997 81 40.512-1.594 55.843-26.244 104.848-26.244 48.993 0 62.753 26.245 105.64 25.406 43.606-.803 71.232-39.638 97.925-78.65 30.887-45.12 43.548-88.75 44.316-90.994-.969-.437-85.029-32.634-85.879-129.439l.118-.035zM414.23 102.178C436.553 75.095 451.636 37.5 447.514-.024c-32.162 1.311-71.163 21.437-94.253 48.485-20.729 24.012-38.836 62.28-33.993 99.036 35.918 2.8 72.591-18.248 94.926-45.272l.036-.047z" />
              </svg>
              Sign in with Apple
            </button>
          </div>
        )}
      ></AppleLogin>
    </>
  );
};

export default RegisterWithApple;
