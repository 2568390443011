import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Menu() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="md:hidden border-t shadow-lg fixed bottom-0 z-50 left-0 right-0 w-full bg-white flex items-center justify-evenly py-4">
      <Link
        to="/"
        className={`text-2xl p-1 transition hover:text-app-primary ${
          currentPath === "/" ? "text-app-primary" : "text-gray-200"
        }`}
        exact="true"
      >
        <i className="fa fa-home"></i>
      </Link>

      <Link
        to="/add-item"
        className={`text-2xl p-1 transition  hover:text-app-primary ${
          currentPath === "/add-item" ? "text-app-primary" : "text-gray-200"
        }`}
      >
        <i className="fa fa-camera"></i>
      </Link>

      <Link
        to="/inbox"
        className={`text-2xl p-1 transition hover:text-app-primary ${
          currentPath.includes("/inbox") ? "text-app-primary" : "text-gray-200"
        }`}
      >
        <i className="fa fa-comment-alt"></i>
      </Link>

      <Link
        to="/profile"
        className={`text-2xl p-1 transition  hover:text-app-primary ${
          currentPath === "/profile" ? "text-app-primary" : "text-gray-200"
        }`}
      >
        <i className="fa fa-user"></i>
      </Link>
    </div>
  );
}
