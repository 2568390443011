import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// Helpers
import api from "../../utils/api";

import { store } from "../../store";
import { toast } from "react-toastify";
import RegisterWithApple from "../../components/RegisterWithApple";

export default function Login() {
  const history = useHistory();
  const { dispatch } = useContext(store);

  const { register, handleSubmit } = useForm();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // handle form submission
  const onSubmit = ({ email, password }) => {
    setErrors({});
    setDisabled(true);
    setLoading(true);

    login(email, password);
  };

  const login = (email, password) => {
    api.get(`${process.env.REACT_APP_API}/sanctum/csrf-cookie`).then(() => {
      api
        .post(`${process.env.REACT_APP_API}/api/login`, { email, password })
        .then((response) => {
          // if (response?.data?.message) {
          // toast.success(response.data.message);
          // }

          localStorage.setItem("asulist_user", true);

          dispatch({
            type: "LOGGEDIN",
            payload: response?.data?.data?.user,
          });

          history.replace("/");
        })
        .catch((err) => {
          if (err?.response?.data.message) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              message: err?.response?.data?.message,
            }));
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch((errors) => console.log(errors.response));
    });
  };

  const responseFacebook = (response) => {
    setDisabled(true);
    setFbLoading(true);

    api
      .post(`${process.env.REACT_APP_API}/fb-login`, {
        email: response.email,
        name: response.name,
        image: response.picture.data.url,
      })
      .then((response) => {
        // console.log(response.data);

        if (response.data.error) {
          alert("Error! Invalid Credentials.", "error");

          setDisabled(false);
          setLoading(false);
          return;
        }

        localStorage.setItem("asulist_user", true);

        dispatch({
          type: "LOGGEDIN",
          payload: response.data.user,
        });

        history.replace("/");
      })
      .catch((error) => {
        // console.log(error.response);

        setDisabled(false);
        setFbLoading(false);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-2 md:py-5">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-6 mb-20 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow-lg shadow-gray-100 sm:rounded-lg sm:px-10 border border-gray-200">
            {Object.keys(errors).length > 0 ? (
              <div className="rounded-md bg-red-50 p-3 mb-3">
                <div className="flex">
                  <div>
                    <h3 className="text-sm font-medium text-red-800">
                      {Object.keys(errors).length > 1 ? "Errors" : "Error"}
                    </h3>
                    <div className="mt-1 text-sm text-red-700">
                      <ul className="list-disc space-y-1 pl-4">
                        {Object.keys(errors).map((err) => (
                          <li key={err}>{errors[err]}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    ref={register()}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    ref={register()}
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm ">
                  <Link
                    to="/forgot-password"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={disabled}
                  className="relative btn btn-dark block w-full"
                >
                  <span>Submit</span>
                  {loading ? (
                    <span className="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 scale-75">
                      <Loader />
                    </span>
                  ) : null}
                </button>
                <Link
                  to="/register"
                  className="relative btn btn-outline-dark block w-full mt-2"
                >
                  <span>Signup</span>
                </Link>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative mb-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <RegisterWithApple />

              {/* <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
