import React from "react";
import { Carousel } from "react-responsive-carousel";

const ItemImagesSlider = ({ item }) => {
  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      autoPlay
      interval={5000}
      infiniteLoop
      className="border bg-gray-100 md:rounded-md overflow-hidden"
    >
      {item?.images.length > 0 ? (
        item?.images?.map?.((image, index) => (
          <div
            key={index + "-" + image.path}
            className="flex w-full max-h-[80vh] md:max-h-full md:h-[450px]"
          >
            <img
              className="slider-image h-full block max-w-full object-cover object-center mx-auto"
              src={`${process.env.REACT_APP_IMAGE_PATH}/${image.path}`}
              alt={image.path + "_" + item.title}
            />
          </div>
        ))
      ) : (
        <div className="flex w-full max-h-[80vh] md:max-h-full md:h-[450px]">
          <img
            className="slider-image h-full block max-w-full object-cover object-center mx-auto"
            src={`${process.env.REACT_APP_IMAGE_PATH}/${item.image}`}
            alt={item.image + " " + item.title}
          />
        </div>
      )}
    </Carousel>
  );
};

export default ItemImagesSlider;
