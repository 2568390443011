import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function ItemCard({ data: item }) {
  const history = useHistory();

  return (
    <div
      className="hover:bg-gray-100 transition p-2 rounded"
      onClick={() => {
        if (item.slug) {
          history.push(`/item/${item.slug}`);
          window.scrollTo(0, 0);
        }
      }}
      style={{ cursor: "pointer" }}
    >
      <img
        className="w-full h-36 sm:h-44 rounded"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src={
          item.image
            ? `${
                item.imagePath
                  ? item.imagePath
                  : process.env.REACT_APP_IMAGE_PATH + "/"
              }${item.image}`
            : "/Default_Image_Thumbnail.png"
        }
        alt={item.title}
      />
      <div className="pt-1">
        <h3 className="text-lg truncate overflow-hidden">{item.title}</h3>
        <p className="text-gray-600 font-light">${item.price}</p>
      </div>
    </div>
  );
}
