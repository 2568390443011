import React from "react";
import Footer from "../../components/Footer";
import ItemCard from "../../components/ItemCard";
import MyMasonry from "../../components/MyMasonry";

const About = () => {
  return (
    <main className="-mx-4 md:-mx-6 lg:-mx-10 pb-[72px] md:pb-[201px] md:mb-0">
      <section className="flex flex-col-reverse md:grid grid-cols-6 md:min-h-[85vh]">
        <div className="col-span-3 lg:col-span-2 bg-app-primary py-5 px-5 lg:py-7 lg:px-6 text-white ">
          <h2 className="uppercase text-gray-200 md:text-lg font-light tracking-widest">
            How it works
          </h2>
          <h1 className="text-2xl lg:text-3xl font-bold pt-5 border-t mt-5 lg:mt-8 pb-10 lg:pb-16 border-b">
            ASU LIST is the simplest, most trusted way to buy and sell locally.
          </h1>

          <div className="mt-4 lg:mt-5">
            <div>
              <a
                href="#!"
                className="btn btn-outline-light inline-block text-white"
              >
                Get the app
              </a>
            </div>
            <p className="text-lg mt-4 lg:mt-5 font-light">
              Get started in less than thirty seconds when you download our free
              app.
            </p>
          </div>
        </div>
        <div className="col-span-3 lg:col-span-4 h-[40vh] md:h-full relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src="/about.jpg"
            alt="About"
          />
        </div>
      </section>
      <section>
        <div className="max-w-5xl mx-auto px-3 md:px-5 pt-10">
          <div className="mb-6">
            <h2 className="text-3xl lg:text-4xl font-bold text-app-primary">
              Browse deals on tons of
              <br /> great items nearby
            </h2>
            <p className="text-gray-700 text-lg lg:text-xl font-light mt-5">
              like furniture, electronics, and cars — and buy <br /> from your
              neighbors.
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-3 ">
            <ItemCard
              data={{ price: 120, imagePath: "/", image: "product-0.jpg" }}
            />
            <ItemCard
              data={{ price: 449, imagePath: "/", image: "product-1.jpg" }}
            />
            <ItemCard
              data={{ price: 5, imagePath: "/", image: "product-2.jpg" }}
            />
            <ItemCard
              data={{ price: 4500, imagePath: "/", image: "product-3.jpg" }}
            />
          </div>
          <div className="border-b border-gray-400 pb-14"></div>
        </div>
      </section>
      <section className="max-w-5xl mx-auto px-3 md:px-5 pt-14 border-b border-gray-400">
        <div className="md:grid grid-cols-2">
          <div className="flex flex-col justify-center md:px-5">
            <h2 className="text-3xl lg:text-4xl font-bold text-app-primary">
              Instantly message <br /> sellers
            </h2>

            <p className="text-gray-700 text-lg lg:text-xl font-light mt-5">
              like furniture, electronics, and cars — and buy <br /> from your
              neighbors.
            </p>
          </div>
          <div className="mt-10 md:mt-0">
            <img
              src="/phone-0.jpg"
              className="w-full"
              alt="Instantly message sellers"
            />
          </div>
        </div>
      </section>
      <section className="max-w-5xl mx-auto px-3 md:px-5 pt-14 border-b border-gray-400">
        <div className="flex flex-col-reverse md:grid grid-cols-2">
          <div className="mt-10 md:mt-0">
            <img
              src="/phone-1.webp"
              className="w-full"
              alt="Instantly message sellers"
            />
          </div>
          <div className="flex flex-col justify-center md:px-5">
            <h2 className="text-3xl lg:text-4xl font-bold text-app-primary">
              Check out people's <br /> profiles
            </h2>

            <p className="text-gray-700 text-lg lg:text-xl font-light mt-5">
              to see ratings, badges, and transaction history.
            </p>
          </div>
        </div>
      </section>
      <section className="max-w-5xl mx-auto px-3 md:px-5 pt-14 border-b border-gray-400">
        <div className="md:grid grid-cols-2">
          <div className="flex flex-col justify-center md:px-5">
            <h2 className="text-3xl lg:text-4xl font-bold text-app-primary">
              Sell something of <br /> your own
            </h2>

            <p className="text-gray-700 text-lg lg:text-xl font-light mt-5">
              by simply taking a picture with your phone. Posting takes less
              than 30 seconds!
            </p>
          </div>
          <div className="mt-10 md:mt-0">
            <img
              src="/phone-2.jpg"
              className="w-full"
              alt="Instantly message sellers"
            />
          </div>
        </div>
      </section>
      <section className="max-w-5xl mx-auto px-3 md:px-5 pt-10">
        <div className="flex flex-col-reverse md:grid grid-cols-2">
          <div className="mt-10 md:mt-0">
            <img
              src="/phone-3.jpg"
              className="w-full"
              alt="Instantly message sellers"
            />
          </div>
          <div className="flex flex-col justify-center md:px-5">
            <h2 className="text-3xl lg:text-4xl font-bold text-app-primary">
              Join millions of <br /> people
            </h2>

            <p className="text-gray-700 text-lg lg:text-xl font-light mt-5">
              on the largest mobile marketplace for local buyers and sellers.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default About;
