import React, { createContext, useReducer } from "react";

const initialState = {
  user: {},
  categories: [],
  updateItems: false,
  isNameModalOpen: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      // case "UPDATE_ITEMS":
      //   return { ...state, updateItems: true };

      case "SET_CATEGORIES":
        return { ...state, categories: action.payload };

      case "LOGGEDOUT":
        return { ...state, user: {} };

      case "LOGGEDIN":
        return { ...state, user: action.payload };
      case "SET_IS_NAME_MODAL_OPEN":
        return { ...state, isNameModalOpen: action.payload };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
