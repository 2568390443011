import moment from "moment";

export function convertToSlug(Text) {
  return (
    Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
      .slice(0, 240) +
    "-" +
    generateUID()
  );
}
export function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export const formatDate = (val, format = "MM.DD.YYYY", formatFrom = "") => {
  if (!val) return "";

  if (format === "diffForHumans") return moment(val).fromNow();

  return moment(val, formatFrom).format(format);
};

export const getAge = (val) => {
  // return Math.floor((Date.now() - new Date(val).getTime()) / (1000 * 60 * 60 * 24 * 365))

  let now = moment().unix();
  let then = moment(val).unix();
  let diff = (now - then) / (60 * 60 * 24 * 365);
  return Math.floor(diff);
};

export async function getZipCode() {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;

          var API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
          var API_URL =
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            lng +
            "&key=" +
            API_KEY;

          fetch(API_URL)
            .then(async (response) => {
              if (!response.ok) {
                reject(response.statusText);
              }
              const data = await response.json();
              var addressComponents = data.results[0].address_components;
              var zipCode = "";
              addressComponents.forEach((component) => {
                if (component.types.includes("postal_code")) {
                  zipCode = component.long_name;
                }
              });

              resolve(zipCode);
            })
            .catch((error) => {
              reject(error);
            });
        },
        (error) => {
          reject(error);
        }
      );
    });
  } else {
    // If the device doesn't support geolocation, prompt the user to enter their zip code manually:
    return Promise.resolve(
      alert(
        "Geolocation is not supported by this device. Please enter your zip code: "
      )
    );
  }
}
// export async function getLocationFromZipCode(zipCode) {
//   if (!zipCode || zipCode.length !== 5) {
//     return;
//   }

//   // Use the Geolocation API to get the user's current position
//   const position = await new Promise((resolve, reject) => {
//     navigator.geolocation.getCurrentPosition(resolve, reject);
//   });

//   // Get the latitude and longitude from the position
//   const lat = position.coords.latitude;
//   const lng = position.coords.longitude;

//   // Use the Google Maps API to reverse geocode the latitude and longitude to get the address information
//   const mapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
//   const mapsApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${mapsApiKey}`;
//   const response = await fetch(mapsApiUrl);
//   const data = await response.json();

//   // Loop through the results to find the address with the correct zip code
//   for (const address of data.results) {
//     for (const component of address.address_components) {
//       if (
//         component.types.includes("postal_code") &&
//         component.short_name === zipCode
//       ) {
//         // Return the name of the location
//         console.log("address: ", address);
//         return address.formatted_address;
//       }
//     }
//   }

//   // If no location was found, return null
//   return null;
// }
