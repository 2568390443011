import React, { useState } from "react";
import { useEffect } from "react";
import MyMasonry from "../../components/MyMasonry";
import PageLoader from "../../components/PageLoader";
import api from "../../utils/api";

const Wishlist = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItems = () => {
    let url = `/wishlist`;

    // if (query.length > 0) {
    //   // p = 1; //reset page on search filter
    //   url += `?search=${query}`;
    // }

    // url += `&page=${p}`;
    // url +=
    //   isActive !== null
    //     ? url.includes("?")
    //       ? `&category=${isActive}`
    //       : `?category=${isActive}`
    //     : "";

    setLoading(true);

    api
      .get(url)
      .then((response) => {
        if (response.data.data) {
          setItems(response.data.data);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className="Home pt-5 pb-20">
      <h3 className="text-2xl md:text-3xl mb-5">
        <i className="far fa-heart mr-3"></i>
        Wishlist
      </h3>
      <MyMasonry items={items} />

      <PageLoader loading={loading} />
    </div>
  );
};

export default Wishlist;
