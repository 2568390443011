import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

import PageLoader from "../../components/PageLoader";

import { store } from "../../store";
import api from "../../utils/api";
import { convertToSlug, getZipCode } from "../../utils/helpers";

import "./edit-item.css";

export default function EditItem() {
  const { state } = useContext(store);
  const history = useHistory();
  const { slug } = useParams();

  const [imgLoading, setImgLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const [thumbnail, setThumbnail] = useState("");

  const [title, setTitle] = useState("");
  const [itemId, setItemId] = useState(null);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [categoryId, setCategoryId] = useState(
    state.categories[0] ? state.categories[0].id : ""
  );
  const [zipCode, setZipCode] = useState("");

  const [uploadedImages, setUploadedImages] = useState([]);

  const inputFile = useRef(null);
  const selectFile = () => inputFile.current.click();

  const onFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    // max 3 files allowed
    var files = [...event.target.files];

    if (files.length > 3 - uploadedImages.length) {
      return toast.error(
        `Please select upto ${3 - uploadedImages.length} ${
          3 - uploadedImages.length > 1 ? "files" : "file"
        }.`
      );
    }

    // apply validations if requested
    let fd = new FormData();
    files.forEach((f) => fd.append("images[]", f));

    setImageAdded(true);
    setImgLoading(true);

    api
      .post("items/images", fd)
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length) {
          setUploadedImages([...uploadedImages, ...response.data.data]);

          if (!thumbnail) setThumbnail(response.data.data[0].path);
        }

        setImgLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        }
        console.log(error.response);
        setImgLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!state.user.id) return toast.error("Please login first to continue");

    if (!categoryId) return toast.error("Please select category to continue");

    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    if (!zipCodePattern.test(zipCode)) return toast.error("Invalid zip code.");

    if (!imageAdded)
      return toast.error("Please add at least one image to continue");

    if (imgLoading) return toast.error("Please wait until images uploaded.");

    setDisabled(true);
    setLoading(true);
    save();
  };

  const save = () => {
    api
      .put(`${process.env.REACT_APP_API_URI}/items/${itemId}`, {
        title,
        slug: convertToSlug(title),
        description,
        price,
        category_id: categoryId,
        zip: zipCode,
        condition: "NEW",
        status: "AVAILABLE",
        user_id: state.user.id,
        meta: description,
        image: uploadedImages[0] ? uploadedImages[0]["path"] : "",
        images: uploadedImages,
      })
      .then((response) => {
        toast.success("Item has been updated.");
        setDisabled(false);
        setLoading(false);

        history.goBack();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        }

        setDisabled(false);
        setLoading(false);
      });
  };

  const fetchItem = () => {
    setLoading(true);

    api
      .get(`items/${slug}`)
      .then((response) => {
        if (response.data.data) {
          setItemId(response.data.data.id);
          setTitle(response.data.data.title);
          setPrice(response.data.data.price);
          setDescription(response.data.data.description);
          setCategoryId(response.data.data.category_id);
          setZipCode(response.data.data.zip);
          // setUploadedImages(response.data.data.images);

          if (response.data.data?.images?.length > 0) {
            setUploadedImages(response.data.data?.images);
          } else if (response.data.data?.image) {
            setUploadedImages([{ path: response.data.data?.image }]);
          }
          setThumbnail(response.data.data.image);

          if (response.data.data.images.length) setImageAdded(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });
  };

  const onRemove = (path) => {
    if (window.confirm("Are you sure to delete?") !== true) return;

    if (path === thumbnail) setThumbnail("");
    if (uploadedImages.length === 1) setImageAdded(false);

    setUploadedImages(uploadedImages.filter((image) => image.path !== path));
  };

  useEffect(() => {
    fetchItem();
  }, []);

  const [loadingZip, setLoadingZip] = useState(false);

  const handleGetZipCode = async () => {
    setLoadingZip(true);
    const zip_code = await getZipCode();
    setZipCode(zip_code);
    setLoadingZip(false);
  };

  return (
    <div
      className="fixed inset-0 bg-white z-50 pb-16 w-full overflow-auto h-screen px-4"
      style={{ zIndex: 60 }}
    >
      <div className="flex justify-between items-center py-4">
        <button className="btn" onClick={() => history.goBack()}>
          <i className="fa fa-chevron-left"></i>
        </button>

        <p className="font-bold text-center m-0">Update your item</p>

        <button className="btn invisible">
          <i className="fa fa-chevron-left"></i>
        </button>
      </div>

      <form className="space-y-2" onSubmit={onSubmit}>
        <div className="border-b flex justify-between items-center">
          <label className="mb-0 text-app-primary">Title</label>
          <input
            type="text"
            placeholder="Sectional counch"
            className="py-1 font-light border-0 text-right w-2/3"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="border-b flex justify-between items-center">
          <label className="mb-0 text-app-primary">Price</label>
          <input
            type="number"
            placeholder="$250.00"
            className="py-1 font-light border-0 text-right w-2/3"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>

        <div className="border-b flex justify-between items-center">
          <label className="mb-0 text-app-primary">Category</label>
          <select
            className="py-1 border-0"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            {state.categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="border-b flex justify-between items-center">
          <label className="mb-0 text-app-primary">Zip Code</label>
          <input
            type="text"
            placeholder="Zip Code"
            className="py-1 font-light border-0 text-right w-2/3"
            value={zipCode}
            required
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleGetZipCode}
            type="button"
            disabled={loadingZip}
            className="relative btn-sm btn-outline-primary rounded-btn mb-3"
          >
            <i
              className={`fas fa-map-marker-alt mr-2 ${
                loadingZip ? "opacity-0" : ""
              }`}
            ></i>
            <span className={`${loadingZip ? "opacity-0" : ""}`}>
              Get my Zip Code
            </span>
            {loadingZip ? (
              <span className="inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 mt-px">
                <Loader />
              </span>
            ) : null}
          </button>
        </div>

        <div className="border-b">
          <label className="mb-2 text-app-primary">Description</label>
          <textarea
            rows="5"
            placeholder="What would you like to tell other users about it?"
            className="border-0 px-0 block w-full font-light"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="text-center relative text-app-primary pt-4 pb-5">
          <button type="button" className="btn text-xl" onClick={selectFile}>
            <i className="fa fa-plus"></i>
          </button>
          <p>Add more images</p>
          {imgLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
              <Loader />
            </div>
          ) : null}

          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="exampleFormControlFile1"
            label="Example file input"
            ref={inputFile}
            onChange={onFileChange}
            multiple
          />
        </div>

        <div className="fixed w-full bottom-0 inset-x-0 px-3 pt-3 bg-gray-100 border-t pb-5">
          <button className="btn btn-primary block w-full">Update Item</button>
        </div>
      </form>

      <p className="text-center text-app-primary py-2 bg-gray-100">
        <small>Preview</small>
      </p>

      <div className="flex py-5">
        {thumbnail && (
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}/${thumbnail}`}
            width="90"
            height="90"
            alt="Thumbnail"
            className="rounded mr-3"
          />
        )}

        <div className="flex-1 flex justify-between">
          <div>
            <h3 className="font-bold text-lg mb-0">{title}</h3>
            <p className="text-base text-gray-700 font-light">{description}</p>
          </div>
          <p className="mb-0 font-bold">{price ? `$${price}` : ""}</p>
        </div>
      </div>

      <div className="flex justify-center items-center space-x-3 mt-1 pb-3 bg-light">
        {uploadedImages.map((img, i) => (
          <div key={i} className="flex flex-col items-center text-center">
            <img
              width="80"
              height="80"
              src={`${process.env.REACT_APP_IMAGE_PATH}/${img.path}`}
              className="rounded object-cover object-center"
              alt="item"
              // onClick={() => setThumbnail(img.path)}
            />
            <small
              className="cursor-pointer text-blue-500 mt-1"
              onClick={() => onRemove(img.path)}
            >
              Remove
            </small>
          </div>
        ))}
      </div>

      <PageLoader loading={loading} />
    </div>
  );
}
