import queryString from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LocationFilterModal from "../Modals/LocationFilterModal";

const LocationFilter = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const parsedQuery = queryString.parse(history.location?.search);

  return (
    <div>
      <LocationFilterModal open={isModalOpen} setOpen={setIsModalOpen} />
      <button
        type="button"
        onClick={() => setIsModalOpen((prevState) => !prevState)}
        className="font-semibold text-app-primary px-2 py-1 inline-flex items-center space-x-2"
      >
        <i className="fas fa-map-marker-alt"></i>{" "}
        <span>{parsedQuery?.zip ? parsedQuery?.zip : "All"}</span>
      </button>
    </div>
  );
};

export default LocationFilter;
