import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import queryString from "query-string";

import { store } from "../../store";
import api from "../../utils/api";
import { formatDate } from "../../utils/helpers";
import moment from "moment";

const Message = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useContext(store);

  const [messageText, setMessageText] = useState("");
  const [chatUser, setChatUser] = useState(null);
  const [chatItem, setChatItem] = useState(null);
  const [messages, setMessages] = useState({});

  console.log("messages: ", messages);

  useEffect(() => {
    const fetchChat = async () => {
      const parsedQuery = queryString.parse(history?.location?.search);
      const response = await api.get(
        `${process.env.REACT_APP_API}/api/items/${parsedQuery.item}/messages/${parsedQuery?.id}`
      );
      const chat = await response;

      const messages = chat?.data?.data?.messages;

      setChatUser(chat?.data?.data?.receiver);
      setChatItem(chat?.data?.data?.item);
      // setMessages(messages);
      setMessages(
        messages.reduce((groupedMessages, message) => {
          if (!groupedMessages[formatDate(message?.created_at)]) {
            groupedMessages[formatDate(message?.created_at)] = [];
          }
          groupedMessages[formatDate(message?.created_at)].push(message);
          return groupedMessages;
        }, {})
      );
    };

    fetchChat();
  }, [location?.search]);

  useEffect(() => {
    if (chatItem?.id) {
      let channelName = `Chat.${state.user.id}.Item.${chatItem.id}`;
      window.Echo.private(channelName).listen("NewMessage", (message) => {
        console.log("New Message >>> ", message);
        if (message)
          setMessages((prevMessages) => {
            console.log("update");
            const messagesCopy = { ...prevMessages };

            if (messagesCopy?.[formatDate(message?.created_at)]?.length > 0) {
              messagesCopy[formatDate(message?.created_at)] = [
                ...messagesCopy[formatDate(message?.created_at)],
                message,
              ];

              return messagesCopy;
            } else {
              messagesCopy[formatDate(message?.created_at)] = [message];
              return messagesCopy;
            }
          });
        // setMessages((prevMessages) => ({
        //   ...prevMessages,
        //   [formatDate(message?.created_at)]: [
        //     ...prevMessages[(formatDate(message?.created_at), message)],
        //   ],
        // }));
      });

      return () => {
        window.Echo.private(channelName).stopListening("NewMessage");
      };
    }
  }, [chatItem]);

  const sendMessage = async (e) => {
    e.preventDefault();

    const parsedQuery = queryString.parse(history?.location?.search);

    try {
      await api.post(
        `${process.env.REACT_APP_API}/api/items/${chatItem?.id}/messages`,
        {
          text: messageText,
          receiver_id: parsedQuery?.id,
        }
      );
      setMessageText("");
    } catch (err) {
      console.log("Send Message Error: ", err);
    }
  };

  const scrollDivRef = useRef();

  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <main className="md:pt-6 md:pb-6">
      <div className="max-w-6xl mx-auto lg:px-5">
        <div
          // style={{ zIndex: 500 }}
          className="fixed z-[100] md:z-0 top-0 left-0 w-full  bg-white md:relative md:border md:rounded overflow-hidden grid md:grid-cols-6 h-screen md:h-[80vh] "
        >
          <div className="col-span-4 flex flex-col overflow-hidden">
            <div className="py-2 px-3 md:px-4 border-b bg-gray-50 md:shadow-sm">
              <div className="flex items-center">
                <button
                  onClick={() => history.goBack()}
                  className="mt-1 mr-3 md:hidden"
                >
                  <ChevronLeftIcon className="w-9 h-9"></ChevronLeftIcon>
                </button>
                <img
                  src={
                    chatUser?.image
                      ? `${process.env.REACT_APP_IMAGE_PATH}/${chatUser?.image}`
                      : "/empty-profile.png"
                  }
                  className="block w-11 h-11 border rounded-full object-cover object-center mr-2"
                  alt="User"
                />
                <div>
                  <h3 className="text-[15px] font-medium text-gray-700">
                    <Link
                      to={`/profile/${chatUser?.id}`}
                      className="hover:text-gray-800 transition"
                    >
                      {chatUser?.name}
                    </Link>
                  </h3>
                  {/* <p className="text-sm font-light text-gray-600">
                    Active few hours ago
                  </p> */}
                </div>
              </div>
            </div>
            <Link
              to={`/item/${chatItem?.slug}`}
              className="py-2 md:hidden px-4 border-b transition shadow-sm bg-gray-50 hover:bg-gray-100"
            >
              <div className="flex items-center">
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}/${chatItem?.image}`}
                  className="block w-11 h-11 rounded object-cover object-center mr-2"
                  alt="User"
                />
                <div className="mr-auto">
                  <h3 className="text-[15px] font-medium text-gray-700">
                    {chatItem?.title}
                  </h3>
                  <p className="text-sm font-light text-gray-600">
                    ${chatItem?.price}
                  </p>
                </div>

                <div className="py-1 px-2.5 text-sm inline-block rounded-full border border-app-primary hover:bg-app-primary hover:text-white transition text-app-primary">
                  See Item details
                </div>
              </div>
            </Link>
            <div
              ref={scrollDivRef}
              className="flex-grow overflow-y-auto flex-col justify-end pl-4 pr-4 py-4 space-y-2"
            >
              {Object.keys(messages).map((date, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="text-center flex items-center text-[13px] text-gray-500 font-light py-5">
                      <div className="h-px flex-1 bg-gray-200"></div>
                      <span className="inline-block mx-4">
                        {moment(date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "MMM DD, YYYY",
                          nextWeek: "MMM DD, YYYY",
                          lastDay: "[Yesterday]",
                          lastWeek: "MMM DD, YYYY",
                          sameElse: "MMM DD, YYYY",
                        })}
                      </span>
                      <div className="h-px flex-1 bg-gray-200"></div>
                    </div>
                    {messages?.[date]?.length > 0
                      ? messages?.[date].map((message) => {
                          if (message?.sender_id === state?.user?.id) {
                            return (
                              <div key={message?.id} className="flex flex-col">
                                <div className="text-right self-end space-y-0.5">
                                  <small className="block text-xs text-gray-600 font-light">
                                    You
                                  </small>
                                  <div className="relative text-left max-w-xs lg:max-w-md bg-app-primary py-1 text-[15px] px-3 rounded font-light text-white">
                                    {message?.text}
                                  </div>
                                  <small className="block text-xs text-gray-600 font-light">
                                    {formatDate(message.created_at, "h:mm a")}
                                  </small>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={message?.id} className="flex flex-col">
                                <div className="text-left flex items-start flex-col self-start space-y-0.5">
                                  <small className="block text-xs text-gray-600 font-light">
                                    {chatUser?.name}
                                  </small>
                                  <div className="relative max-w-xs lg:max-w-md inline-block bg-gray-700 py-1 text-[15px] px-3 rounded font-light text-white">
                                    {message?.text}
                                  </div>

                                  <small className="block text-xs text-gray-600 font-light">
                                    {formatDate(message.created_at, "h:mm a")}
                                  </small>
                                </div>
                              </div>
                            );
                          }
                        })
                      : null}
                  </React.Fragment>
                );
              })}
            </div>
            <form onSubmit={sendMessage} className="relative flex items-center">
              <input
                type="text"
                required
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="border-t focus:outline-none font-light text-gray-700 text-[15px] py-4 block w-full pl-3 pr-[86px]"
                placeholder="Message..."
              />
              <button className="absolute right-3 text-sm font-medium border rounded-full px-3.5 py-1.5 inline-block disabled:text-gray-500 disabled:bg-gray-50 text-gray-600 hover:bg-gray-50">
                Send
              </button>
            </form>
          </div>
          <div className="hidden md:block col-span-2 border-l overflow-auto">
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}/${chatItem?.image}`}
              className="w-full object-cover max-h-60 object-center"
              alt="Item"
            />
            <div className="p-4">
              <h4 className="font-semibold text-gray-800">{chatItem?.title}</h4>
              <h3 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-5">
                ${chatItem?.price}
              </h3>
              <Link
                to={`/item/${chatItem?.slug}`}
                className="py-1 px-2.5 text-sm inline-block rounded-full border border-app-primary hover:bg-app-primary hover:text-white transition text-app-primary"
              >
                See Item details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Message;
