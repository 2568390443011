import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { store } from "../../store";
import api from "../../utils/api";

export default function ProfileInfo() {
  const { state, dispatch } = useContext(store);

  const [name, setName] = useState(state.user?.name);
  const [username, setUsername] = useState(state.user?.username);
  const [email, setEmail] = useState(state.user?.email);
  const [phoneNumber, setPhoneNumber] = useState(state.user?.mobile);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // api.get(`${process.env.REACT_APP_API}/sanctum/csrf-cookie`).then(() => {
      api
        .put(`${process.env.REACT_APP_API}/api/user`, {
          name,
          username,
          email,
          mobile: phoneNumber,
        })
        .then((response) => {
          if (response?.data?.message) {
            toast.success(response?.data?.message);
          }
          if (response?.data?.data) {
            dispatch({ type: "LOGGEDIN", payload: response.data.data });
          }
          setLoading(false);
        })
        .catch((errors) => {
          if (errors?.response?.data?.message) {
            toast.error(errors?.response?.data?.message);
          }
          setLoading(false);
        });
    // });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-8 max-w-3xl mx-auto px-2 sm:px-3 md:px-5 pt-5 pb-44 md:pb-20 divide-y divide-gray-200 h-full"
    >
      {/* <div className="space-y-8 divide-y divide-gray-200"> */}
      <div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Basic Information
          </h3>
          {/* <p className="mt-1 text-sm text-gray-500">
            Use a permanent address where you can receive mail.
          </p> */}
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-4 sm:gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <div className="mt-1">
              <input
                required
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              username
            </label>
            <div className="mt-1">
              <input
                required
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                required
                id="email"
                name="email"
                type="email"
                disabled
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <div className="mt-1">
              <input
                id="mobile"
                name="mobile"
                type="number"
                autoComplete="mobile"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="fixed z-20 bottom-[75px] md:bottom-0 left-0 right-0 w-full max-w-3xl mx-auto px-5 py-3 bg-white">
        <div className="flex justify-end">
          {/* <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Cancel
          </button> */}
          <button
            type="submit"
            disabled={loading}
            className="ml-3 relative disabled:opacity-75 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-600 py-2 px-5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <span className={loading ? "opacity-0" : ""}>Save</span>
            {loading ? (
              <span className="absolute transform scale-75">
                <Loader />
              </span>
            ) : null}
          </button>
        </div>
      </div>
    </form>
  );
}
