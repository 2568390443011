import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import EditItemModel from "../../components/EditItemModel";
import PageLoader from "../../components/PageLoader";
import { store } from "../../store";
import AddItemModal from "../../UIComponents/Modals/AddItemModal";
import api from "../../utils/api";

export default function Items() {
  const { state } = useContext(store);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const fetchItems = () => {
    let url = `/items?user=${state?.user?.id}`;

    // url += `&page=${p}`;
    // url +=
    //   isActive !== null
    //     ? url.includes("?")
    //       ? `&category=${isActive}`
    //       : `?category=${isActive}`
    //     : "";

    setLoading(true);

    api
      .get(url)
      .then((response) => {
        if (response.data.data) {
          setItems([...response.data.data]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure want to delete the item")) {
      api
        .delete(`items/${id}`)
        .then((response) => {
          if (response?.data?.data) {
            toast.success(response?.data?.message);
            setItems((prevItems) => {
              return prevItems.filter((item) => item.id !== id);
            });
          }
        })
        .catch((error) => console.log(error.response));
    }
  };

  const [isEditingItem, setIsEditingItem] = useState(false);
  const [isCreatingItem, setIsCreatingItem] = useState(false);

  const handleEdit = (item) => {
    setIsEditingItem(item?.id);
  };

  return (
    <div className="-mx-2 py-4 pb-20 md:pb-5 overflow-x-hidden">
      <AddItemModal open={isCreatingItem} setOpen={setIsCreatingItem} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Items</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Items created by you
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setIsCreatingItem(true)}
            type="button"
            className="btn btn-primary hidden md:inline-block"
          >
            Sell Item
          </button>
          <Link to={"/add-item"} className="btn btn-primary md:hidden">
            Sell Item
          </Link>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-6">
          <div className="inline-block min-w-full py-2 align-top md:px-6 lg:px-8 ">
            <div className="overflow-x-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg md:max-h-[40vh] lg:max-h-[50vh]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Meta
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {items.map((item, index) => {
                    return (
                      <tr
                        key={item?.id}
                        onClick={() => {
                          history.push(`/item/${item?.slug}`);
                        }}
                        className="hover:bg-gray-100 transition cursor pointer cursor-pointer"
                      >
                        <td className="align-top whitespace-normal py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-start">
                            <div className="h-12 w-12 flex-shrink-0">
                              <img
                                className="h-12 w-12 rounded object-cover"
                                src={`${process.env.REACT_APP_IMAGE_PATH}/${item.image}`}
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div
                                className="font-medium text-gray-900"
                                title={item?.title}
                              >
                                <div className="truncate-3 min-w-[220px] max-w-[300px] lg:max-w-[400px]">
                                  {item?.title}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="align-top whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">${item?.price}</div>
                        </td>
                        <td className="align-top whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {item?.category?.name}
                          </span>
                        </td>
                        <td
                          className="align-top whitespace-normal px-3 py-4 text-sm text-gray-500"
                          title={item?.meta}
                        >
                          <p className="truncate-3 min-w-[200px] max-w-[300px] lg:max-w-[400px]">
                            {item?.meta}
                          </p>
                        </td>
                        <td className="align-top relative whitespace-nowrap space-x-5 py-4 pl-3 pr-4 text-right text-lg font-medium sm:pr-6">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(item);
                            }}
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900 p-0.5 hidden md:inline-block"
                          >
                            <i className="fas fa-pencil"></i>
                            <span className="sr-only">, {item?.title}</span>
                          </button>
                          <Link
                            to={`/item/${item?.slug}/edit`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(item);
                            }}
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900 p-0.5 md:hidden"
                          >
                            <i className="fas fa-pencil"></i>
                            <span className="sr-only">, {item?.title}</span>
                          </Link>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(item?.id);
                            }}
                            className="text-red-600 hover:text-red-900 p-0.5"
                          >
                            <i className="fas fa-trash-alt"></i>
                            <span className="sr-only">, {item?.title}</span>
                          </button>
                        </td>
                        <td>
                          <EditItemModel
                            show={isEditingItem === item.id}
                            setShow={setIsEditingItem}
                            item={item}
                            setItem={(currItem) => {
                              setItems((prevItems) => {
                                return prevItems.map((item) => {
                                  if (currItem.id === item.id) {
                                    return currItem;
                                  }
                                  return item;
                                });
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PageLoader loading={loading} />
    </div>
  );
}
