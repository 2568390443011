import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const Terms = () => {
  return (
    <>
      <section className="pt-10 pb-20 md:pb-60">
        <div className="max-w-3xl mx-auto px-2 leading-relaxed">
          <h1 className="mb-8 text-gray-800 text-3xl md:text-4xl font-bold">
            Terms of Service
          </h1>
          <div className="font-light text-gray-700 space-y-2 text-[17px]">
            <p>
              Thank you for using The ASU List, a platform designed to provide a
              safer and more convenient way for ASU students to buy, sell,
              trade, and discuss items locally. By using this platform, you
              agree to the following Terms of Service:
            </p>
            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Eligibility
            </h3>
            <p>
              The ASU List is only available to ASU students who have been
              verified. All users must provide proof of their ASU student status
              to use the platform. The ASU List reserves the right to request
              additional information from users to verify their eligibility.
            </p>

            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Prohibited Items
            </h3>
            <p>
              You may not use The ASU List to sell or trade illegal or
              prohibited items. This includes, but is not limited to, weapons,
              drugs, and stolen property. Any listings found to be in violation
              of this policy will be removed, and the user may be banned from
              using the platform.
            </p>
            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Accuracy of Listings
            </h3>
            <p>
              Users are responsible for the accuracy of the information provided
              in their listings. The ASU List is not responsible for any
              misrepresentations made by users or for any resulting
              consequences.
            </p>
            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Payment and Transactions
            </h3>
            <p>
              The ASU List does not handle payments or transactions between
              buyers and sellers. All transactions are the sole responsibility
              of the buyer and seller. We strongly recommend meeting in person
              in a public place to complete any transactions.
            </p>
            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Code of Conduct
            </h3>
            <p>
              The ASU List is a community-based platform, and we expect all
              users to behave in a respectful and responsible manner. Users must
              not harass or engage in any form of discriminatory behavior
              towards other users. Any violation of this policy may result in
              the removal of the user's account.
            </p>

            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Limited Liability
            </h3>
            <p>
              The ASU List is not responsible for any loss or damage resulting
              from the use of the platform, including but not limited to, any
              loss or damage caused by inaccurate information or fraudulent
              activity.
            </p>

            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Modifications
            </h3>
            <p>
              The ASU List reserves the right to modify or terminate the
              platform at any time, for any reason, without notice.
            </p>

            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Indemnification
            </h3>
            <p>
              By using The ASU List, you agree to indemnify and hold harmless
              the platform and its owners, employees, and affiliates from any
              claims, damages, or expenses arising from your use of the
              platform.
            </p>

            <h3 className="text-gray-700 text-xl font-bold pt-2">
              Governing Law
            </h3>
            <p>
              These Terms of Service are governed by the laws of the State of
              Arizona, without regard to its conflict of law provisions.
            </p>
            <p>
              By using The ASU List, you agree to abide by these Terms of
              Service. If you have any questions or concerns about these terms,
              please{" "}
              <Link
                to="/contact"
                className="text-app-primary hover:text-app-primary-dark transition hover:underline"
              >
                Contact Us
              </Link>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
