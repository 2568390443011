import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const Privacy = () => {
  return (
    <>
      <section className="pt-10 pb-20 md:pb-60">
        <div className="max-w-3xl mx-auto px-2 space-y-4 leading-relaxed">
          <h1 className="mb-8 text-gray-800 text-3xl md:text-4xl font-bold">
            Privacy Policy
          </h1>
          <div className="font-light text-gray-700 text-[17px]">
            <p className="mb-2.5">
              Thank you for using The ASU List, a platform designed to provide a
              safer and more convenient way for ASU students to buy, sell,
              trade, and discuss items locally. We respect your privacy and are
              committed to protecting your personal information. This Privacy
              Policy describes how we collect, use, and disclose information
              about you.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">Information We Collect</h3>
            <p className="mb-2.5">
              We collect information you provide to us when you use The ASU
              List, such as your name, email address, ASU student ID number, and
              any additional information you choose to provide in your user
              profile or listings. We also collect information about your
              activity on the platform, such as the items you browse or
              purchase, and your interactions with other users.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">
              How We Use Your Information
            </h3>
            <p className="mb-2.5">
              We use your information to verify your eligibility to use The ASU
              List, to facilitate transactions between buyers and sellers, to
              communicate with you about your account, and to improve and
              personalize the platform.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">
              How We Share Your Information
            </h3>
            <p className="mb-2.5">
              We share your information with other users when you engage in
              transactions on the platform. We may also share your information
              with service providers who assist us in operating the platform, or
              as required by law.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">
              Security of Your Information
            </h3>
            <p className="mb-2.5">
              We take reasonable measures to protect your information from
              unauthorized access or disclosure. However, no security system is
              completely secure, and we cannot guarantee the security of your
              information.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">Your Choices</h3>
            <p className="mb-2.5">
              You can choose to limit the information you provide to us, but
              this may limit your ability to use certain features of the
              platform. You can also choose to opt-out of receiving promotional
              emails from us.
            </p>
          </div>

          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">Children's Privacy</h3>
            <p className="mb-2.5">
              The ASU List is not intended for children under the age of 13. We
              do not knowingly collect personal information from children under
              the age of 13.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">Changes to this Policy</h3>
            <p className="mb-2.5">
              We may update this Privacy Policy from time to time. If we make
              any material changes, we will notify you by email or by posting a
              notice on the platform.
            </p>
          </div>
          <div className="font-light text-gray-700 text-[17px]">
            <h3 className="text-xl font-bold mb-1">Contact Us</h3>
            <p className="mb-2.5">
              If you have any questions or concerns about this Privacy Policy,
              please{" "}
              <Link
                to="/contact"
                className="text-app-primary hover:text-app-primary-dark transition hover:underline"
              >
                Contact Us
              </Link>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
