import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { store } from "../../store";
import api from "../../utils/api";
import { convertToSlug, getZipCode } from "../../utils/helpers";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";

export default function AddItemModal({ open, setOpen }) {
  const { state, dispatch } = useContext(store);
  const { register, handleSubmit } = useForm();
  const [step, setStep] = useState(1);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [price, setPrice] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [errors, setErrors] = useState({});

  console.log("errors: ", errors);
  const onBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const inputFile = useRef(null);
  const selectFile = () => inputFile.current.click();

  const onFileChange = (event) => {
    setErrors((prevErrors) => {
      const errorsCopy = { ...prevErrors };
      delete errorsCopy.image;
      return errorsCopy;
    });
    event.stopPropagation();
    event.preventDefault();

    setImageAdded(true);

    // max 3 files allowed
    var files = [...event.target.files];

    if (files.length > 3 - uploadedImages.length) {
      return setErrors((prevErrors) => ({
        ...prevErrors,
        image: `Please select upto ${3 - uploadedImages.length} ${
          3 - uploadedImages.length > 1 ? "files" : "file"
        }.`,
      }));
    }

    // apply validations if requested
    let fd = new FormData();
    files.forEach((f) => fd.append("images[]", f));

    setImageAdded(true);
    setIsUploadingImage(true);

    api
      .post("/items/images", fd)
      .then((response) => {
        console.log("uploadImage response: ", response.data);
        if (response.data.data.length) {
          setUploadedImages([...uploadedImages, ...response.data.data]);
          setIsUploadingImage(false);
        }
      })
      .catch((error) => {
        let errors = {};

        if (error?.response?.data?.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            errors = { [key]: error.response.data.errors?.[key]?.[0] };
          });
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...errors,
          }));
        }

        setIsUploadingImage(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (!state.user.id) return;

    if (!imageAdded) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Please add at least one image to continue",
      }));
      // return toast.error("Please add at least one image to continue");
      return;
    }

    if (step < 4) return setStep(step + 1);

    if (!categoryId) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category: "Please select category to continue",
      }));
      return;
    }
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    if (zipCodePattern.test(zipCode)) {
      setErrors((prevErrors) => {
        const errorsCopy = { ...prevErrors };
        delete errorsCopy.zipCode;
        return errorsCopy;
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipCode: "Invalid zip code.",
      }));
      return;
    }
    setDisabled(true);
    setLoading(true);
    save();
  };

  const save = () => {
    api
      .post(`${process.env.REACT_APP_API_URI}/items`, {
        title,
        slug: convertToSlug(title),
        description,
        price,
        category_id: categoryId,
        condition: "NEW",
        status: "AVAILABLE",
        user_id: state.user.id,
        meta: description,
        image: uploadedImages[0] ? uploadedImages[0]["path"] : "",
        images: uploadedImages,
        zip: zipCode,
      })
      .then((response) => {
        // console.log(response.data);

        // dispatch({ type: "UPDATE_ITEMS", payload: response.data });
        toast.success("Item has been posted.");
        setDisabled(false);
        setLoading(false);

        onHide();

        history.push(`/item/${response?.data?.data?.slug}`);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          // toast.error(error.response.data.message);
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error?.response?.data?.errors,
          }));
        }

        setDisabled(false);
        setLoading(false);
      });
  };

  const onHide = () => {
    setOpen(false);
    setStep(1);
    setTitle("");
    setDescription("");
    setPrice("");
    setCategoryId("");
    setZipCode("");
    setUploadedImages([]);
    setImageAdded(false);
  };

  // const [thumbnail, setThumbnail] = useState("");

  const onRemove = (path) => {
    if (window.confirm("Are you sure to delete?") !== true) return;

    // if (path === thumbnail) setThumbnail("");
    if (uploadedImages.length === 1) setImageAdded(false);

    setUploadedImages(uploadedImages.filter((image) => image.path !== path));
  };

  useEffect(() => {
    if (!open) {
      onHide();
    }
  }, [open]);

  const [loadingZip, setLoadingZip] = useState(false);

  const handleGetZipCode = async () => {
    setLoadingZip(true);
    const zip_code = await getZipCode();
    setZipCode(zip_code);
    setLoadingZip(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded bg-white px-4 pt-5 pb-4 text-left shadow-xl  transition-all sm:my-8 w-full sm:max-w-lg sm:py-8 sm:px-6 flex flex-col min-h-[520px]">
                <p className="text-center mb-4 font-light">Sell an item</p>

                <ul className="space-x-1.5 flex justify-center mb-2">
                  <li
                    className={`${
                      step >= 1 ? " bg-gray-900" : "bg-gray-300"
                    } w-12 h-[3px]`}
                  ></li>
                  <li
                    className={`${
                      step >= 2 ? " bg-gray-900" : "bg-gray-300"
                    } w-12 h-[3px]`}
                  ></li>
                  <li
                    className={`${
                      step >= 3 ? " bg-gray-900" : "bg-gray-300"
                    } w-12 h-[3px]`}
                  ></li>
                  <li
                    className={`${
                      step >= 4 ? " bg-gray-900" : "bg-gray-300"
                    } w-12 h-[3px]`}
                  ></li>
                </ul>

                {Object.keys(errors).length > 0 ? (
                  <div className="text-sm my-1 text-red-500">
                    Errors:
                    <ul className="list-disc list-inside">
                      {Object.keys(errors).map((error) => (
                        <li key={error}>{errors[error]}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <h3 className="text-center font-medium text-gray-700 text-2xl">
                  What are you selling today?
                </h3>

                <form
                  className="mt-5 h-full flex flex-1 flex-col"
                  onSubmit={onSubmit}
                >
                  {step === 1 && (
                    <div className="relative">
                      {uploadedImages.length !== 3 ? (
                        <div className="drag-drop-image relative">
                          {isUploadingImage ? (
                            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                              <Loader />
                            </div>
                          ) : null}
                          <div
                            className="flex items-center justify-center border-2 rounded-md border-dashed flex-col py-8 px-5"
                            style={{ opacity: isUploadingImage ? 0.3 : 1 }}
                          >
                            <div className="mb-2 text-5xl text-gray-700">
                              <i className="fas fa-images"></i>
                            </div>

                            {/* <span className="header">Drag & Drop</span> */}
                            <span>
                              <button
                                type="button"
                                className="text-lg text-app-primary hover:text-app-primary-dark transition text-center"
                                onClick={selectFile}
                              >
                                Add Images
                              </button>
                            </span>
                            <input
                              type="file"
                              className="d-none"
                              id="exampleFormControlFile1"
                              label="Example file input"
                              ref={inputFile}
                              onChange={onFileChange}
                              multiple
                              hidden
                              accept="image/*"
                            />
                            <span className="text-sm font-light mt-1 text-gray-500">
                              Supports: JPEG, JPG, PNG
                            </span>
                          </div>
                        </div>
                      ) : null}

                      {uploadedImages.length > 0 ? (
                        <div className="flex justify-center my-5 items-center space-x-3">
                          {uploadedImages.map((img, i) => (
                            <div key={i} className="flex flex-col items-center">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}/${img.path}`}
                                className="rounded w-14 h-14 object-cover object-center"
                                alt="item"
                                // onClick={() => setThumbnail(img.path)}
                              />

                              <button
                                type="button"
                                className="text-blue-500 text-xs py-0.5 mt-0.5"
                                onClick={() => onRemove(img.path)}
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  )}

                  {step === 2 && (
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="2 bedroom lease + balcony"
                        className="focus:outline-none py-1 px-1 mb-3 block w-full border-b border-gray-400 font-light"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                      <textarea
                        type="text"
                        rows={3}
                        placeholder="Write something about item"
                        className="focus:outline-none py-1 px-1 block w-full border-b border-gray-400 font-light"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      <input
                        type="number"
                        placeholder="Price"
                        className="focus:outline-none py-1 px-1 mb-3 block w-full border-b border-gray-400 font-light"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  {step === 4 && (
                    <div>
                      <select
                        autoComplete="off"
                        className="focus:outline-none py-2 px-1 mb-3 block w-full border-b border-gray-400 font-light"
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        required
                      >
                        <option value="">Select Category</option>

                        {state.categories.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <div>
                        <input
                          id="zipcode"
                          type="text"
                          placeholder="Zip Code"
                          value={zipCode}
                          required
                          onChange={(e) => setZipCode(e.target.value)}
                          className="focus:outline-none py-1 px-1 block w-full border-b border-gray-400 font-light"
                        />
                      </div>
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={handleGetZipCode}
                          type="button"
                          disabled={loadingZip}
                          className="relative btn-sm btn-outline-primary rounded-btn mb-3"
                        >
                          <i
                            className={`fas fa-map-marker-alt mr-2 ${
                              loadingZip ? "opacity-0" : ""
                            }`}
                          ></i>
                          <span className={`${loadingZip ? "opacity-0" : ""}`}>
                            Get my Zip Code
                          </span>
                          {loadingZip ? (
                            <span className="inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 mt-px">
                              <Loader />
                            </span>
                          ) : null}
                        </button>
                      </div>
                    </div>
                  )}

                  <button
                    variant="dark"
                    className={`mt-auto mb-2 ${
                      (step === 1 ? uploadedImages.length === 0 : true)
                        ? ""
                        : ""
                    } relative btn btn-dark block w-full`}
                    type="submit"
                    disabled={disabled}
                  >
                    <span>{step === 4 ? "Post an Item" : "Continue"}</span>

                    {loading ? (
                      <span className="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 scale-75">
                        <Loader />
                      </span>
                    ) : null}
                  </button>

                  {step > 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={onBack}
                      disabled={disabled}
                    >
                      Back
                    </button>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
