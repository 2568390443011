import React, { useRef } from "react";

import { useWindowSize } from "@react-hook/window-size";
import {
  MasonryScroller,
  useContainerPosition,
  usePositioner,
  useResizeObserver,
} from "masonic";
import ItemCard from "./ItemCard";

export default function MyMasonry({ items }) {
  const containerRef = useRef(null);
  const [windowWidth, windowHeight] = useWindowSize();
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
  ]);

  let columnCount = 2;

  if (width >= 510) {
    columnCount = 3;
  }

  if (width >= 690) {
    columnCount = 4;
  }

  if (width >= 930) {
    columnCount = 5;
  }
  if (width >= 1240) {
    columnCount = 6;
  }

  const positioner = usePositioner(
    { width, columnWidth: 180, columnGutter: 8, columnCount },
    [items]
  );

  const resizeObserver = useResizeObserver(positioner);

  return (
    <MasonryScroller
      positioner={positioner}
      resizeObserver={resizeObserver}
      containerRef={containerRef}
      items={items}
      height={windowHeight}
      offset={offset}
      render={ItemCard}
    />
  );
}
