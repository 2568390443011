import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { store } from "../../store";

// Components
import MyMasonry from "../../components/MyMasonry";
import ItemCard from "../../components/ItemCard";
import PageLoader from "../../components/PageLoader";

// Helpers
import api from "../../utils/api";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function Home({ query }) {
  const { state } = useContext(store);

  const [items, setItems] = useState([]);

  const location = useLocation();

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchItems = (isMore = false, limit = 50, page) => {
    let p = page;
    let url = `/items?limit=${limit}`;

    const parsedQuery = queryString.parse(location?.search);

    if (parsedQuery?.s?.length > 0) {
      // p = 1; //reset page on search filter
      url += `&search=${parsedQuery?.s}`;
    }

    url += `&page=${p}`;
    if (parsedQuery?.c?.length > 0) {
      url += `&category=${parsedQuery?.c}`;
    }

    if (parsedQuery?.zip?.length > 0) {
      url += `&zip=${parsedQuery?.zip}`;
    }

    setLoading(true);

    api
      .get(url)
      .then((response) => {
        if (response.data.data.length === 0) {
          if (page === 1) {
            setItems(response.data.data);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          return setHasMore(false);
        }

        let totalItems = isMore
          ? [...items, ...response.data.data]
          : [...response.data.data];

        if (response.data.data.length < limit) {
          setHasMore(false);
        }

        setTimeout(() => {
          setItems(totalItems);
          setLoading(false);
          setPage(page + 1);
        }, 1500);
      })
      .catch((error) => console.log(error.response));
  };

  const fetchMoreItems = () => {
    setHasMore(true);
    fetchItems(true, 36, page);
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      setPage(1);
      setHasMore(true);
      fetchItems(false, 36, 1);
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(
    () => fetchItems(false, 36, page),
    // eslint-disable-next-line
    []
  );

  return (
    <div className="Home py-5">
      <h3 className="text-2xl md:text-3xl mb-5">
        <i className="fas fa-tags mr-2"></i>
        {state.categories.find(
          (c) => String(c.id) === queryString.parse(location?.search)?.c
        )?.name || "Listings"}
      </h3>

      <InfiniteScroll
        style={{ overflow: "initial" }}
        dataLength={items.length} //This is important field to render the next data
        next={fetchMoreItems}
        hasMore={hasMore}
        // endMessage={
        //   <p style={{ textAlign: 'center' }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        // loader={<PageLoader />}
      >
        <MyMasonry items={items} />
        {/* <Row>
          {items.map((item, index) => (
            <Col md={3} key={index}>
              <ItemCard data={item} />
            </Col>
          ))}
        </Row> */}
      </InfiniteScroll>

      <PageLoader loading={loading} />
    </div>
  );
}
