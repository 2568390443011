import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { getZipCode } from "../../utils/helpers";
import Loader from "../../components/Loader";

export default function LocationFilterModal({ open, setOpen }) {
  const history = useHistory();
  const [zipCode, setZipCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const parsedQuery = queryString.parse(history.location?.search);

    if (zipCode.length > 0) {
      const zipCodePattern = /^\d{5}(-\d{4})?$/;
      if (!zipCodePattern.test(zipCode))
        return toast.error("Invalid zip code.");

      const queryArray = [];

      if (parsedQuery?.s) {
        queryArray.push(`s=${parsedQuery?.s}`);
      }
      if (parsedQuery?.c) {
        queryArray.push(`c=${parsedQuery?.c}`);
      }

      queryArray.push(`zip=${zipCode}`);

      history.push(`/?${queryArray.join("&")}`);
    } else {
      const queryArray = [];

      if (parsedQuery?.s) {
        queryArray.push(`s=${parsedQuery?.s}`);
      }
      if (parsedQuery?.c) {
        queryArray.push(`c=${parsedQuery?.c}`);
      }

      history.push(`/?${queryArray.join("&")}`);
    }

    setOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    const zip = queryString.parse(location?.search)?.zip;
    if (location.pathname === "/" && zip?.length > 0) {
      setZipCode(zip);
    } else {
      setZipCode("");
    }
  }, [location?.search, location.pathname]);

  const [loadingZip, setLoadingZip] = useState(false);

  const handleGetZipCode = async () => {
    setLoadingZip(true);
    const zip_code = await getZipCode();
    setZipCode(zip_code);
    setLoadingZip(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded bg-white  text-left shadow-xl  transition-all sm:my-8 w-full sm:max-w-md p-6 flex flex-col ">
                <div className="flex items-center justify-between mb-3">
                  <button className="text-sm text-gray-700 invisible opacity-0 pointer-events-none">
                    Cancel
                  </button>
                  <h3 className="text-center font-bold text-gray-700 text-xl">
                    Set Location
                  </h3>
                  <button
                    className="text-sm text-gray-700"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="text-center text-gray-700 mx-auto w-44"
                >
                  <h4 className="mb-4 font-medium text-sm">
                    Where are you searching?
                  </h4>
                  <button
                    onClick={handleGetZipCode}
                    type="button"
                    disabled={loadingZip}
                    className="relative btn btn-outline-primary rounded-btn mb-3"
                  >
                    <i
                      className={`fas fa-map-marker-alt mr-2 ${
                        loadingZip ? "opacity-0" : ""
                      }`}
                    ></i>
                    <span className={`${loadingZip ? "opacity-0" : ""}`}>
                      Get my Zip Code
                    </span>
                    {loadingZip ? (
                      <span className="inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-75 mt-px">
                        <Loader />
                      </span>
                    ) : null}
                  </button>
                  {/* <small className="block my-3 font-semibold">Or</small> */}
                  <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="text-gray-500 border px-3 py-2 rounded-md text-sm font-light text-center inline-block mb-6"
                    placeholder="Enter ZIP code"
                  />
                  <button
                    type="submit"
                    className="btn btn-outline-dark rounded-btn block w-full"
                  >
                    Apply
                  </button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
