import React from "react";
import Loader from "react-loader-spinner";

export default function PageLoader({ loading }) {
  return (
    <>
      {loading && (
        <div>
          <div className="fixed inset-0 w-full flex items-center justify-center bg-white bg-opacity-50"></div>
          <div className="fixed inset-0 w-full flex items-center justify-center">
            <Loader type="Puff" color="#EE3622" height={80} width={80} />
          </div>
        </div>
      )}
    </>
  );
}
