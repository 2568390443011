import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from "../../store";
import api from "../../utils/api";

const Inbox = () => {
  const { state } = useContext(store);
  const [messages, setMessages] = useState([]);

  console.log("state.user.id: ", state.user.id);
  console.log("messages: ", messages);

  useEffect(() => {
    const fetchMessages = async () => {
      const response = api.get(`${process.env.REACT_APP_API}/api/messages`);
      const messages = await response;
      setMessages(messages?.data?.data || []);
    };
    fetchMessages();
  }, []);

  return (
    <main className="pt-6 pb-24 md:pb-6">
      <div className="max-w-6xl mx-auto lg:px-5">
        <h1 className="text-gray-800 text-2xl md:text-[27px] font-bold mb-6">
          Inbox
        </h1>
        <section className="border rounded overflow-hidden">
          <div className="px-4 border-b pt-1">
            <button className="inline-block font-medium text-app-primary text-[15px] py-2.5 px-4 border-b-[3px] border-app-primary">
              Messages
            </button>
          </div>
          {/* <div className="px-4 py-3 border-b flex items-center">
            <input
              className="h-5 w-5 mr-3 inline-block rounded border-gray-200 text-indigo-600 focus:ring-indigo-500"
              type="checkbox"
              name="select-all"
              id="select-all"
            />
            <button
              disabled
              className="text-sm font-medium border rounded-full px-3.5 py-1.5 inline-block disabled:text-gray-500 disabled:bg-gray-50 hover:bg-gray-50 text-gray-600"
            >
              Delete
            </button>
          </div> */}
          <div className="divide-y">
            {/* <div>
              <Link
                to="/inbox/message/123"
                className="flex items-center px-4 py-2 transition bg-app-primary hover:bg-opacity-25 bg-opacity-20"
              >
                <input
                  className="h-5 w-5 mr-3 inline-block rounded border-gray-200 text-indigo-600 focus:ring-indigo-500"
                  type="checkbox"
                  name="select-all"
                  id="select-all"
                />
                <div className="flex">
                  <img
                    src="https://via.placeholder.com/44"
                    className="block w-11 h-11 rounded-full object-cover object-center mr-2"
                    alt="User"
                  />
                  <div>
                    <h3 className="text-[15px] font-medium text-gray-700">
                      Noah Fullbright
                    </h3>
                    <p className="text-sm font-light text-gray-600">
                      You: Hi, is this still available?
                    </p>
                    <small className="text-gray-500 text-xs block">
                      1 minute ago
                    </small>
                  </div>
                </div>
              </Link>
            </div> */}
            {messages.map((message) => (
              <div key={message?.message_id}>
                <Link
                  to={`/inbox/message?id=${
                    message?.sender_id === state?.user?.id
                      ? message?.receiver_id
                      : message?.sender_id
                  }&item=${message?.item_id}`}
                  className="flex items-center px-4 py-2 transition hover:bg-gray-50"
                >
                  {/* <input
                    className="h-5 w-5 mr-3 inline-block rounded border-gray-200 text-indigo-600 focus:ring-indigo-500"
                    type="checkbox"
                    name="select-all"
                    id="select-all"
                  /> */}
                  <div className="flex">
                    <img
                      src={
                        message?.sender_id === state?.user?.id
                          ? message?.receiver_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}/${message?.receiver_image}`
                            : "/empty-profile.png"
                          : message?.sender_image
                          ? `${process.env.REACT_APP_IMAGE_PATH}/${message?.sender_image}`
                          : "/empty-profile.png"
                      }
                      className="block w-11 shrink-0 h-11 rounded-full object-cover object-center mr-2"
                      alt="User"
                    />
                    <div>
                      <h3 className="text-[15px] font-medium text-gray-700">
                        {message?.sender_id === state?.user?.id
                          ? message?.receiver_name
                          : message?.sender_name}
                      </h3>
                      <div
                        style={{
                          display: "table",
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{ display: "table-cell" }}
                          className="text-sm font-light truncate text-gray-600"
                        >
                          {message.message_text}
                        </p>
                      </div>
                      {/* <small className="text-gray-500 text-xs block">
                        1 minute ago
                      </small> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
      </div>
    </main>
  );
};

export default Inbox;
