import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";

// import Tag from "../../tag.png";

// Components
import MyMasonry from "../../components/MyMasonry";

// Helpers
import api from "../../utils/api";

import { store } from "../../store";
import EditItemModel from "../../components/EditItemModel";
import Footer from "../../components/Footer";
import ItemImagesSlider from "../../UIComponents/Sliders/ItemImagesSlider";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/helpers";
import ChatModal from "../../UIComponents/Modals/ChatModal";

export default function Item() {
  const history = useHistory();
  const { state } = useContext(store);
  const { slug } = useParams();

  const [show, setShow] = useState(false);
  const [item, setItem] = useState({ images: [], user: {} });
  const [items, setItems] = useState([]);

  const fetchItem = (callback) => {
    api
      .get(`items/${slug}`)
      .then((response) => {
        if (response.data) {
          setItem(response.data.data);
          callback(response.data.data);
        }
      })
      .catch((errors) => history.push("/"));
  };

  const fetchItems = (item) => {
    api
      .get(
        `items/${item?.id}/related?${
          item?.category?.id ? `category=${item?.category?.id}` : ""
        }`
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setItems(response.data.data);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const formattedTime = (val) => {
    return moment.duration(-moment().diff(moment(val))).humanize(true);
  };

  const formatCurrency = (val) => {
    return `$${parseFloat(val).toFixed(2)}`;
  };

  const onEdit = () => {
    setShow(true);
  };

  useEffect(() => {
    fetchItem((item) => {
      fetchItems(item);
    });
  }, [slug]);

  const saveItem = () => {
    if (!state?.user?.id) {
      toast.error("Please login first to save the item");
      return;
    }
    if (!item?.wishlisted) {
      api
        .post(`wishlist/${item?.id}`)
        .then((response) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            setItem((prevState) => ({ ...prevState, wishlisted: true }));
          }
        })
        .catch((errors) => {
          toast.error("Something went wrong");
        });
    } else {
      api
        .delete(`wishlist/${item?.id}`)
        .then((response) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            setItem((prevState) => ({ ...prevState, wishlisted: false }));
          }
        })
        .catch((errors) => {
          toast.error("Something went wrong");
        });
    }
  };

  const [chatModalOpen, setChatModalOpen] = useState(false);

  const handleChat = (e) => {
    e.stopPropagation();

    if (!state?.user?.id) {
      toast.error("Please login first to send message.");
      return;
    }
    setChatModalOpen(true);
  };

  return (
    <>
      <ChatModal item={item} open={chatModalOpen} setOpen={setChatModalOpen} />
      {/* Desktop view */}
      <div className="hidden md:block relative pb-60">
        {item.id && (
          <div className="mt-10">
            <div className="grid md:grid-cols-12 gap-5">
              <div className="md:col-span-8 xl:col-span-9">
                <ItemImagesSlider item={item} />

                <div className="border-t border-b pt-4 pb-5 mt-5">
                  <h4 className="text-2xl font-bold text-gray-700 mb-2">
                    Description
                  </h4>
                  <p className="font-light text-gray-600">{item.description}</p>
                </div>
                <div className="border-b pt-3 pb-3 space-x-3">
                  {/* {state?.user?.id ? ( */}
                  <button
                    onClick={saveItem}
                    className={
                      "py-1 inline-block px-3 rounded-full bg-gray-200 bg-opacity-75 hover:bg-opacity-100 transition-opacity text-[15px] text-gray-600"
                    }
                  >
                    {item?.wishlisted ? (
                      <>
                        <i
                          className="fa fa-heart mr-1.5"
                          style={{ cursor: "pointer" }}
                        ></i>
                        Unsave
                      </>
                    ) : (
                      <>
                        <i
                          className="far fa-heart mr-1.5"
                          style={{ cursor: "pointer" }}
                        ></i>
                        Save
                      </>
                    )}
                  </button>
                  {/* ) : null} */}
                  <a
                    href="#!"
                    className="py-1 inline-block px-3 rounded-full bg-gray-200 bg-opacity-75 hover:bg-opacity-100 transition-opacity text-[15px] text-gray-600"
                  >
                    <i
                      className="far fa-share mr-1.5"
                      style={{ cursor: "pointer" }}
                    ></i>
                    Share
                  </a>
                </div>
              </div>

              <div className="md:col-span-4 xl:col-span-3 pt-3 border-t">
                <h2 className="flex text-3xl mb-2 text-gray-800 font-bold">
                  {item.title}
                </h2>

                <p className="text-4xl mb-5 text-gray-700 font-bold">
                  ${item.price}
                </p>

                <ul className="text-gray-600 font-light mb-6">
                  <li>Posted {formattedTime(item.created_at)}</li>
                  <li>{item?.category?.name}</li>
                  {item?.user?.id === state?.user?.id ? (
                    <li>
                      <a
                        href="#!"
                        className="underline text-blue-500"
                        onClick={onEdit}
                      >
                        Edit
                      </a>
                    </li>
                  ) : null}
                </ul>
                {item.user ? (
                  <div>
                    {/* <a
                      href={`tel:+${item.user.mobile}`}
                      className="block btn btn-dark"
                    >
                      Make offer
                    </a> */}

                    {state?.user?.id !== item?.user?.id ? (
                      <button
                        className="btn btn-dark mt-2 block w-full"
                        onClick={handleChat}
                      >
                        Message Seller
                      </button>
                    ) : null}
                    <div className="flex items-center text-center mt-3 pb-3 border-b space-x-3">
                      {/* {state?.user?.id ? ( */}
                      <button
                        onClick={saveItem}
                        className="flex-1 btn btn-outline-dark transition-colors"
                      >
                        {item?.wishlisted ? (
                          <>
                            <i
                              className="fa fa-heart mr-1.5"
                              style={{ cursor: "pointer" }}
                            ></i>
                            Unsave
                          </>
                        ) : (
                          <>
                            <i
                              className="far fa-heart mr-1.5"
                              style={{ cursor: "pointer" }}
                            ></i>
                            Save
                          </>
                        )}
                      </button>
                      {/* ) : null} */}
                      <a
                        href="#!"
                        className="flex-1 btn btn-outline-dark transition-colors"
                      >
                        <i
                          className="far fa-share mr-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                        Share
                      </a>
                    </div>
                    <div className="my-1 border-b pb-1">
                      <Link
                        to={`/profile/${item.user.id}`}
                        className="text-gray-800 font-medium p-2 flex items-center hover:bg-gray-100 transition-colors rounded-md"
                      >
                        <img
                          variant="top"
                          src={
                            item.user.image
                              ? `${process.env.REACT_APP_IMAGE_PATH}/${item.user.image}`
                              : "/empty-profile.png"
                          }
                          className="rounded-full object-cover w-[60px] h-[60px] object-center  mr-2 border"
                          alt=""
                        />
                        <div>
                          <h6 className="-mb-1">{item.user.name}</h6>

                          <div>
                            <small className="text-gray-500 font-light">
                              Member since{" "}
                              {formatDate(item?.user?.created_at, "MMMM YYYY")}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <h3 className="text-2xl font-medium text-gray-800 mb-4 mt-5">
          More like this
        </h3>

        <MyMasonry items={items} />

        <EditItemModel
          show={show}
          setShow={setShow}
          item={item}
          setItem={setItem}
        />
      </div>

      {/* Mobile View */}
      <div className="block md:hidden Item-mobile bg-white absolute left-0 right-0 -top-10 z-40 pb-20">
        <div className="absolute w-full flex justify-between inset-x-0 top-10 z-50">
          <button className="btn" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left text-gray-800 text-xl"></i>
          </button>
          {/* {state?.user?.id ? ( */}
          <button onClick={saveItem} className="btn">
            {item.wishlisted ? (
              <i className="fa fa-heart text-gray-800 text-xl"></i>
            ) : (
              <i className="far fa-heart text-gray-800 text-xl"></i>
            )}
          </button>
          {/* ) : null} */}
        </div>

        <div className="mt-5">
          <div className="ml-auto p-0">
            <ItemImagesSlider item={item} />
          </div>

          <div className="mr-auto px-5">
            <div className="mt-3 mb-2 text-gray-500 font-light text-[15px]">
              <p>
                Posted {formattedTime(item.created_at)} in{" "}
                {item?.category?.name}
              </p>
            </div>

            <h2 className="flex justify-between" style={{ color: "#3B3B3B" }}>
              <span className="font-semibold text-2xl">{item.title}</span>
              <span className="font-medium text-3xl">
                {formatCurrency(item.price)}
              </span>
            </h2>

            <p className="font-light text-gray-600 mt-3 mb-3">
              {item.description}
            </p>

            {state.user.id === item?.user_id && (
              <Link
                to={`/item/${item.slug}/edit`}
                className="block underline text-blue-500 mb-6"
              >
                Edit
              </Link>
            )}
          </div>
        </div>

        {item.user && (
          <div className="block px-4 mx-5 py-2 border rounded-md hover:bg-gray-50">
            <div className="flex justify-between items-center">
              <Link
                to={`/profile/${item.user.id}`}
                className="flex pr-5 items-center"
              >
                <img
                  variant="top"
                  alt={item?.user?.name}
                  src={
                    item.user.image
                      ? `${process.env.REACT_APP_IMAGE_PATH}/${item.user.image}`
                      : "/empty-profile.png"
                  }
                  className="rounded-full object-cover w-[40px] h-[40px] object-center  mr-2 border"
                />
                <div>
                  <h3 className="-mb-1">{item.user.name}</h3>
                  <div>
                    <small className="text-gray-500 font-light">
                      Member since{" "}
                      {formatDate(item?.user?.created_at, "MMMM YYYY")}
                    </small>
                  </div>
                </div>
              </Link>

              {/* <a
                href={`tel:+${item.user.mobile}`}
                className="btn-sm btn-primary"
                style={{ whiteSpace: "nowrap" }}
              >
                Send Offer
              </a> */}
            </div>

            {state?.user?.id !== item?.user?.id ? (
              <button
                className="btn-sm btn-outline-dark mt-2 block w-full"
                onClick={handleChat}
              >
                Message Seller
              </button>
            ) : null}
          </div>
        )}

        <h3 className="text-lg px-5 my-4">More like this</h3>

        <div className="px-5">
          <MyMasonry items={items} />
        </div>
      </div>

      <Footer />
    </>
  );
}
