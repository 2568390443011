import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { store } from "../../store";
import api from "../../utils/api";
import Loader from "../../components/Loader";

export default function EnterNameModal() {
  const { state, dispatch } = useContext(store);
  const [name, setName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    api
      .put(`${process.env.REACT_APP_API}/api/user/name`, {
        name,
      })
      .then((response) => {
        if (response?.data?.data) {
          setIsLoading(false);
          dispatch({ type: "LOGGEDIN", payload: response.data.data });
          dispatch({ type: "SET_IS_NAME_MODAL_OPEN", payload: false });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  return (
    <Transition.Root show={state.isNameModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form onSubmit={handleSubmit}>
                  <div className="sm:flex sm:items-start mb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Please enter your name
                      </Dialog.Title>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="Name"
                      className="inline-block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                        type="Name"
                        name="Name"
                        required
                        id="Name"
                        className="block w-full px-3 py-2 border rounded-md border-gray-300 shadow-sm focus:outline-none focus:border-app-primary focus:ring-app-primary sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="block relative w-full btn btn-primary"
                    >
                      <span className={isLoading ? "opacity-0" : ""}>
                        Submit
                      </span>
                      {isLoading ? (
                        <span className="block absolute top-1/2 left-1/2 transform scale-75 mt-px -translate-y-1/2 -translate-x-1/2">
                          <Loader />
                        </span>
                      ) : null}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
