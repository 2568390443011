import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import api from "../../utils/api";

const HeaderSearch = ({ onSearch }) => {
  const location = useLocation();
  const [query, setQuery] = useState(
    queryString.parse(location?.search)?.s || ""
  );
  const [items, setItems] = useState([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [loading, setLoading] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fetchItems = (query) => {
    api.get(`/items?limit=15&search=${query}`).then((response) => {
      if (response?.data?.data) {
        setItems(response?.data?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (query && shouldSearch) {
      setLoading(true);
      const delay = setTimeout(() => fetchItems(query), 500);
      return () => {
        setLoading(false);
        clearTimeout(delay);
      };
    }
    if (!query) {
      setItems([]);
    }
  }, [query, shouldSearch]);

  const handleSearchChange = (event) => {
    setShow(true);
    setQuery(event?.target?.value);
    setShouldSearch(true);
  };

  useEffect(() => {
    const s = queryString.parse(location?.search)?.s;

    if (location.pathname === "/" && s?.length > 0) {
      setQuery(s);
    } else {
      setQuery("");
    }
  }, [location?.search]);

  const [show, setShow] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setShow(false);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
    setShow(false);
  };

  return (
    <div
      ref={ref}
      className="relative w-full rounded-xl transition-all"
      onClick={() => setShow(true)}
      onFocus={() => setShow(true)}
    >
      <form onSubmit={handleSubmit} className="relative">
        <button className="absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-500 hover:text-gray-600 transition">
          <i className="fas fa-search text-lg"></i>
        </button>
        <input
          className="w-full text-sm lg:text-[15px] bg-gray-50 border focus:outline-none font-light rounded-full pl-4 pr-6 py-2"
          placeholder="Search"
          value={query}
          onChange={handleSearchChange}
        />
      </form>

      {query.length > 0 && items.length > 0 && !loading ? (
        <div
          className={`${
            !show ? "hidden" : ""
          } absolute max-h-72 overflow-y-auto z-50 w-full md:w-[500px] min-w-full left-0 mt-2 right-0 bg-white border border-gray-200 rounded-md shadow-md`}
        >
          <div className=" w-full overflow-y-auto divide-y divide-gray-200 text-gray-800">
            {items.map((item) => (
              <Link
                to={`/item/${item?.slug}`}
                onClick={() => {
                  setItems([]);
                  setQuery("");
                }}
                key={item?.id}
                value={item}
                className={classNames(
                  "cursor-pointer px-3 py-2 flex items-center hover:bg-gray-100 transition"
                )}
              >
                <img
                  className="w-14 h-12 border rounded-md object-cover object-center"
                  src={`${process.env.REACT_APP_IMAGE_PATH}/${item?.image}`}
                  alt={item?.title}
                />
                <span className="text-base ml-3">{item?.title}</span>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
      {(query !== "" && items.length === 0) || loading ? (
        <div
          className={`${
            !show ? "hidden" : ""
          } absolute max-h-72 overflow-y-auto z-50 w-full md:w-[500px] min-w-full left-0 mt-2 right-0 bg-white border border-gray-200 rounded-md shadow-md`}
        >
          {loading ? (
            <div className="px-4 py-2 text-sm text-gray-500 flex justify-center items-center">
              <Loader />
            </div>
          ) : null}
          {query !== "" && items.length === 0 && !loading ? (
            <p className="px-4 py-3 text-sm text-gray-500 ">No items found.</p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default HeaderSearch;
